const initial = {
  currency: {
    show: true,
    grow: 1,
  },
  nop: {
    show: true,
    grow: 1,
  },
  infinity: {
    show: true,
    grow: 1,
  },
  enabled: {
    show: true,
    grow: 1,
  },
  unop: {
    show: true,
    grow: 1,
  },
  realizedPnL: {
    show: true,
    grow: 1,
  }
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_CCY_COLUMNS":
      settings[action.payload.username].ccyColumns = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_CCY_COLUMNS":
      if(settings[action.payload].ccyColumns === undefined){
        settings[action.payload].ccyColumns = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].ccyColumns;
    default:
      return state;
  }
}
