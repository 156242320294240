const initial = {
  side: {
    show: true,
    grow: 1,
  },
  product: {
    show: true,
    grow: 1,
  },
  price: {
    show: true,
    grow: 2,
  },
  stopPx: {
    show: true,
    grow: 2,
  },
  qty: {
    show: true,
    grow: 2,
  },
  cumQty: {
    show: true,
    grow: 2,
  },
  remQty: {
    show: true,
    grow: 2,
  },
  maxShow: {
    show: true,
    grow: 2,
  },
  type: {
    show: true,
    grow: 1,
  },
  tif: {
    show: true,
    grow: 1,
  },
  bic: {
    show: true,
    grow: 2.2,
  },
  expDate: {
    show: true,
    grow: 2,
  },
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_LIVE_COLUMNS":
      settings[action.payload.username].liveColumns = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_LIVE_COLUMNS":
      if(settings[action.payload].liveColumns === undefined){
        settings[action.payload].liveColumns = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].liveColumns;
    default:
      return state;
  }
}
