const errors = {
  1: "Market not found",
  10: "Account not selected",
  11: "Unsupported order characteristic",
  13: "Incorrect OrderQty specified",
  1000: "Unsupported/unknown OrdType",
  1001: "Unsupported/unknown TimeInForce",
  1003: "Incorrect MinQty",
  1004: "MinQty is more than OrderQty",
  1005: "Invalid ExpireDate",
  1006: "Critical error...call admin support",
  1007: "Invalid minimum quantity clip size",
  1008: "Invalid quantity increment",
  1010: "MaxShow is more than OrderQty",
  1011: "Unsupported/unknown Side",
  1013: "Unsupported/unknown SelfMatchPreventionInstruction",
  1014: "Invalid price (most likely 0 or less in a market)",
  1015: "Market order cannot match",
  1016: "FOK/FAK limit order cannot match",
  1017: "FOK/FAK partial match",
  1018: "Self trade match protection",
  1019: "Market closed",
  1022: "Incorrect OrdType for iceberg order (cannot be market, has to be limit)",
  1023: "Incorrect TimeInForce for iceberg order (has to be Day, GTC or GTD)",
  1024: "Incorrect TimeInForce used when specifying MinQty (has to be Fak/Immediate or Cancel)",
  1025: "Incorrect MaxShow",
  1030: "Currency limit breach",
  1031: "Counterparty limit breach",
  1032: "All limits exhausted",
  3: "Day Order expired (business day roll)",
  C: "Expired",
  F: "GTD expired",
  G: "Market closed",
  H: "Account disabled",
  I: "UserLogin/Trader disabled",
  J: "Self Match Trade Protection",
  W: "Limit order not matched (FAK)",
  X: "Market order not matched",
  Y: "Limit order partially matched (FAK)",
  Z: "Market order partially matched",
  revise: {
    1: "Order not found",
    10: "Account not selected",
    13: "Order not found",
    99: "Unknown reason",
    1000: "Invalid OrderQty",
    1001: "Inalid ExpireDate",
    1006: "Invalid Extent",
    1007: "Invalid Price",
    1013: "SMTP activation",
    1015: "Order not revised (nothing changed)",
    1016: "Not authorized",
    1030: "Currency limit breach",
    1031: "Counterparty limit breach",
  }
}

export default errors;
