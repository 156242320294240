import React from 'react';
import { Animate } from 'react-move';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Formatter from '../../utils/Formatter';
import BestPriceCalc from '../../utils/BestPriceCalc';
import { connect } from 'react-redux';
import { theme } from '../../utils/Theme';
import './QuoteBoard.css';
import produce from 'immer';
import _ from 'lodash';

class QuoteBoard extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      additionalRows: [],
      live: this.getLive(),
      prevMarketPrice: null,
    }
  }
  tableHeads = ["Product","S","","Qty","Buy","Sell","Qty","Last","Total"];
  refs = {};
  running = {};

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if(prevProps.marketPrice !== this.props.marketPrice){
      const prevMarketPrice = produce(prevProps.marketPrice, draft => {});
      this.setState({prevMarketPrice: prevMarketPrice});
    }
  }

  isNewPrice = (price, mkt, side) => {
    if(!price)
      return false;
    if(!this.state.prevMarketPrice)
      return false;
    if(!this.state.prevMarketPrice[mkt.securityID])
      return false;
    if(!this.state.prevMarketPrice[mkt.securityID][0])
      return false;
    let fprice = this.state.prevMarketPrice[mkt.securityID][0][side].find(e => e.mdEntryPx === price);
    let timestamp = this.running[mkt.securityID + "P" + price + "S" + side];
    if(fprice){
      if(!timestamp)
        return false;
      if(new Date() - timestamp > 1000)
        return false;
      return true;
    }
    if(!this.running[mkt.securityID + "P" + price + "S" + side]){
      this.running[mkt.securityID + "P" + price + "S" + side] = new Date();
      return true;
    }
    if(new Date() - timestamp > 1000)
      return false;
    return true;
  }

  getLive = () => {
    let settings = JSON.parse(localStorage.getItem("settings"));
    if(settings[this.props.currentUser].qbLive === undefined){
      this.setLive(false);
      return false;
    }
    return settings[this.props.currentUser].qbLive;
  }

  setLive = (live) => {
    let settings = JSON.parse(localStorage.getItem("settings"));
    settings[this.props.currentUser].qbLive = live;
    localStorage.setItem("settings",JSON.stringify(settings));
  }

  expandCollapse = (mkt, i) => {
    if(this.isLastRow(mkt, i))
      return;
    let ar = produce(this.state.additionalRows, draft => {
      if(!draft.find(e => e.securityID === mkt.securityID)){
        draft.push({
          rows: 0,
          securityID: mkt.securityID,
        });
      }
      let index = draft.findIndex(e => e.securityID === mkt.securityID);
      draft[index].rows = (draft[index].rows > i) ? i : draft[index].rows + 5;
    });
    this.setState({additionalRows: ar});
  }

  isLastRow = (mkt, i) => {
    let mktPrice = this.props.marketPrice[mkt.securityID];
    let lastForSpecificMarket = this.props.lastTrades.filter(e => e.securityID === mkt.securityID);
    if(!mktPrice)
      return false;
    if(!mktPrice[0]["buy"][i] && !mktPrice[0]["sell"][i] && !lastForSpecificMarket[i])
      return true;
    return false;
  }

  expandSign = (mktData, mktPrice, i, securityID) => {
    let lastForSpecificMarket = this.props.lastTrades.filter(e => e.securityID === securityID);
    if(!mktPrice)
      return "";
    if(mktPrice[0]["buy"].length <= 1 && mktPrice[0]["sell"].length <= 1 && lastForSpecificMarket.length <= 1)
      return "";
    return mktData ? (mktData.rows === i ? "+" : "-") : "+";
  }

  renderRow(mkt,i,security) {
    let mktPrice = this.props.marketPrice[mkt.securityID];
    let stats = this.props.marketStats.find(e => e.securityID === mkt.securityID);
    let mktData = this.state.additionalRows.find(e => e.securityID === mkt.securityID);
    let thisPrxBuy = BestPriceCalc.getPriceValue(mktPrice,0,"buy","mdEntryPx",i,"");
    let thisPrxSell = BestPriceCalc.getPriceValue(mktPrice,0,"sell","mdEntryPx",i,"");
    let scrnQtyBuy = BestPriceCalc.getScrnByRawPx(mktPrice,"buy",thisPrxBuy,"");
    let scrnQtySell = BestPriceCalc.getScrnByRawPx(mktPrice,"sell",thisPrxSell,"");
    let secStatus = (security === undefined) ? "" : security.status;
    let sign = this.expandSign(mktData, mktPrice, i, mkt.securityID);
    return(
      <tr>
        {i === 0
          ? <td style={styles.productStyle}>{mkt.currencyBase}/{mkt.currencyTerm}</td>
          : <td></td>
        }
        {i === 0
          ? <td style={styles.statusStyle}>{Formatter.formatOBStatus(secStatus)}</td>
          : <td></td>
        }
        {(i % 5) === 0
          ? <td onClick={() => {this.expandCollapse(mkt,i)}} style={styles.plusButtonStyle}>
              {sign}
            </td>
          : <td></td>
        }
        <td>
          {Formatter.formatQty(BestPriceCalc.getPriceValue(mktPrice,0,"buy","mdEntrySize",i,""))}
          {scrnQtyBuy === "" ? "" : " ("+Formatter.formatQty(scrnQtyBuy)+")"}
        </td>
        {this.renderPrice(thisPrxBuy, mkt, "buy")}
        {this.renderPrice(thisPrxSell, mkt, "sell")}
        <td>
          {Formatter.formatQty(BestPriceCalc.getPriceValue(mktPrice,0,"sell","mdEntrySize",i,""))}
          {scrnQtySell === "" ? "" : " ("+Formatter.formatQty(scrnQtySell)+")"}
        </td>
         {this.renderLast(mkt, i)}
        {i === 0
          ? <td>{(stats ? Formatter.formatQty(stats.cumQty) : 0)}</td>
          : <td></td>
        }
      </tr>
    )
  }

  renderLast = (mkt, i) => {
    let lastForSpecificMarket = this.props.lastTrades.filter(e => e.securityID === mkt.securityID);
    if(lastForSpecificMarket.length === 0)
      return <td></td>;
    let currentLast = lastForSpecificMarket[i];
    if(!currentLast)
      return <td></td>;
    return <td>{Formatter.formatPrice(currentLast.lastPx,mkt)}{" "}{this.lastDirectionIcon(currentLast)}
      {" "}{currentLast.lastQty}{" - "}
      {new Date(currentLast.transactTime / 1000).toISOString().substr(11, 8)}</td>
  }

  lastDirectionIcon = (currentLast) => {
    let color = currentLast.side === "1" ? theme(this.props.theme,'buy') : theme(this.props.theme,'sell');
    if(currentLast.direction === 0)
      return <i class="fas fa-square" style={{color:color}}></i>;
    if(currentLast.direction === 1)
      return <i class="fas fa-arrow-up" style={{color:color}}></i>;
    return <i class="fas fa-arrow-down" style={{color:color}}></i>;
  }

  renderPrice = (price, mkt, side) => {
    if(!price)
      return <td onDoubleClick={(e) => {this.props.onOpenOrderModal(mkt.securityID,side.toUpperCase(),e)}}></td>
    let isNew = this.isNewPrice(price, mkt, side);
    return(
      isNew
      ? <CSSTransition key={mkt.securityID + "P" + price + "S" + side} in={true} timeout={700} classNames={"newprx"}>
          <td onDoubleClick={(e) => {this.props.onOpenOrderModal(mkt.securityID,side.toUpperCase(),e)}}>
            {Formatter.formatPrice(price,mkt)}
          </td>
        </CSSTransition>
      : <td onDoubleClick={(e) => {this.props.onOpenOrderModal(mkt.securityID,side.toUpperCase(),e)}}>
          {Formatter.formatPrice(price,mkt)}
        </td>
    )
  }

  priceClass = (isNew, price, mkt) => {
    return isNew ? "priceBlink" : "";
  }

  toggleLive = () => {
    this.setState({live: !this.state.live});
    this.setLive(!this.state.live);
  }

  liveCheck = () => {
    return(this.state.live === true
      ? <i className="fas fa-check-square mr-1"></i>
      : <i className="far fa-square mr-1"></i>
    )
  }

  filterLive = (mkt) => {
    let mktPrx = this.props.marketPrice[mkt.securityID];
    if(mktPrx === undefined){
      if(this.state.live)
        return false;
      else
        return true;
    }
    if(!this.state.live){
      return true;
    }
    else{
      if(mktPrx[0].buy.length === 0 && mktPrx[0].sell.length === 0)
        return false
      return true;
    }
  }

  render() {
    return(
      <div className="w-100 h-100">
        <span className="mb-2 font-weight-bold d-flex flex-row justify-content-between">
          <div>Quote Board</div>
          <div onClick={this.toggleLive}>{this.liveCheck()}Live</div>
        </span>
        <div className="overflow-auto box-content mt-2">
        <table className="customTable">
          <thead>
            <tr>
              {this.tableHeads.map(thead => {
                return <th
                  style={{position:"sticky",top:0,zIndex:10,backgroundColor:theme(this.props.theme,'bg'),textAlign:(thead==="S"?"center":"")}}>{thead}</th>
              })}
            </tr>
          </thead>
          <TransitionGroup component={null}>
          <tbody>
            {this.props.marketDefArray.filter(this.filterLive).map(mkt => {
              let security = this.props.securityDefArray.find(sct => sct.securityID === mkt.securityID);
              let mktData = this.state.additionalRows.find(e => e.securityID === mkt.securityID);
              let extraRows = mktData ? mktData.rows : 0;
              let rows = [];
              for(let i=0; i < extraRows + 1; i++){
                if(this.isLastRow(mkt,i)){
                  i !== 0
                    ? rows.push(<tr><td colSpan={9}>&nbsp;</td></tr>)
                    : rows.push(this.renderRow(mkt,i,security));
                }
                else
                  rows.push(
                    this.renderRow(mkt,i,security)
                  )
                // rows.push(
                //   this.renderRow(mkt,i,security)
                // )
                // if(this.isLastRow(mkt, i))
                //   break;
              }
              return rows;
            })}
          </tbody>
          </TransitionGroup>
        </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    theme: state.theme,
    currentUser: state.currentUser,
  }
}

export default connect(mapStateToProps,null)(QuoteBoard);

const styles = {
  plusButtonStyle: {
    width:"5ch",
    textAlign:'center',
  },
  statusStyle: {
    width:"5ch",
    textAlign:'center',
  },
  productStyle: {
    width:"11ch",
  }
}
