import React from 'react';

const initialLayout = {
  lg: [
    {i: "0", x: 0, y: 0, w: 3, h: 8, isResizable: false},
    {i: "1", x: 3, y: 0, w: 3, h: 8, isResizable: false},
    {i: "2", x: 6, y: 0, w: 3, h: 8, isResizable: false},
    {i: "3", x: 9, y: 0, w: 3, h: 8, isResizable: false},
    {i: "live", x: 0, y: 8, w: 12, h: 8, minH: 8},
    {i: "book", x: 0, y: 16, w: 12, h: 12, minH: 8},
  ],
}

class LayoutService extends React.Component{
  static initialLayout(username){
    if(!username)
      return;
    let settings = JSON.parse(localStorage.getItem("settings"));
    return (settings[username].layouts === undefined) ? initialLayout : settings[username].layouts;
  }
  static saveLayout(layouts,username){
    let settings = JSON.parse(localStorage.getItem("settings"));
    settings[username].layouts = layouts;
    localStorage.setItem("settings",JSON.stringify(settings));
  }
  static positionOrderWidgetsQuote(widgets){
    let pos = {
      startX: 6,
      startY: 0,
    }
    let intC = 0;
    for(let i=0;i<widgets.length;i++){
      if(!isNaN(widgets[i].i)){
        widgets[i].x = (intC%2 === 0) ? pos.startX : pos.startX + 3;
        widgets[i].y = pos.startY + Math.floor(intC/2)*8;
        intC++;
      }
      if(isNaN(widgets[i].i)){
        widgets[i].y = widgets[i].y + (widgets[i].y === 0 ? 16 : 8);
      }
    }
    return widgets;
  }
  static positionOrderWidgetsDefault(widgets){
    let intC = 0;
    for(let i=0;i<widgets.length;i++){
      if(!isNaN(widgets[i].i)){
        widgets[i].x = 0 + intC*3;
        widgets[i].y = 0;
        intC++;
      }
      if(isNaN(widgets[i].i)){
        widgets[i].y = widgets[i].y + 8;
      }
    }
    return widgets;
  }
  static maxY(layouts){
    let max = -1;
    for(let i=0;i<layouts.length;i++){
      if(layouts[i].y > max)
        max = layouts[i].y;
    }
    return max;
  }
}

export default LayoutService;
