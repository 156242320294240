import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { theme } from '../../utils/Theme';

class LiveSettings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      window: false,
      columns: this.props.columns,
    }
  }

  componentDidUpdate(){
    if(this.state.columns !== this.props.columns)
      this.setState({columns: this.props.columns});
  }

  toggleWindow = () => {
    this.setState({window: !this.state.window});
  }

  getCheck = (key) => {
    let columns = Object.assign({},this.state.columns);
    if(columns[key].show)
      return <i className="fas fa-check-square mr-1"></i>
    else
      return <i className="far fa-square mr-1"></i>
  }

  toggleCheck = (k) => {
    let columns = Object.assign({},this.state.columns);
    columns[k].show = !columns[k].show;
    this.setState({columns: columns});
    this.props.update({
      username: this.props.currentUser,
      data: columns,
    });
  }

  render(){
    return(
      <div>
        <i class="fas fa-cogs revise" onClick={this.toggleWindow}></i>
        {this.state.window ? <div className="dropdownContent d-flex flex-column justify-content-start align-items-center"
          style={{backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
          <div className="pt-1 mb-1 w-100 text-center"
            style={{backgroundColor:theme(this.props.theme,'body'),fontWeight:'bold',color:theme(this.props.theme,'text')}}>
              Columns
          </div>
          {Object.keys(this.state.columns).map(k => {
            return (
              <div className="d-flex flex-row justify-content-start align-items-center w-75"
                onClick={() => this.toggleCheck(k)}
              >{this.getCheck(k)}{k}</div>
            )
          })}
        </div> : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return{
    currentUser: state.currentUser,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(LiveSettings);
