import React from 'react';
import { Navbar, Button, NavDropdown, Nav } from 'react-bootstrap';
import styles from './TopNavbar.css';
import { theme } from '../../utils/Theme';
import { connect } from 'react-redux';

class TopNavbar extends React.Component {
  constructor(props){
    super(props);
  }
  render() {
    let acc = this.props.selectedAccount;
    return (
      <Navbar style={{backgroundColor:theme(this.props.theme,'bg')}} variant={this.props.theme}>
        <Navbar.Brand>
          {'DEMO' + (acc !== null ? ' - Selected BIC is ' + acc.accountID + ' (' + acc.name + ')' : '') + ' ' + this.props.username}
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link className={this.props.blink === true ? "blink" : null} onClick={() => {this.props.onOpenModal()}}>
              Select BIC
            </Nav.Link>
            <NavDropdown className="pr-4" title="Menu">
              <NavDropdown.Item>Contact us</NavDropdown.Item>
              <NavDropdown.Item>Change Password</NavDropdown.Item>
              <NavDropdown.Item onClick={() => this.props.onOpenSettings()}>Settings</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => {this.props.logOut()}}>
              Log out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = state => {
  return{
    theme: state.theme,
  }
}

export default connect(mapStateToProps,null)(TopNavbar);
