import React from 'react';
import F from '../../utils/Formatter';
import { Column, Table, AutoSizer } from 'react-virtualized';
import Tooltip from 'react-tooltip-lite';
import { connect } from 'react-redux';

class RevisedOrders extends React.Component {
  constructor(props){
    super(props);
  }

  rowGetter = (index,orders) => {
    return orders[index];
  }

  render(){
    let orders = this.props.revisedOrders;
    return(
      <AutoSizer>
        {({width,height}) => (
          <Table
            headerHeight={20}
            headerClassName="vHead"
            height={height-30}
            noRowsRenderer={() => {return <div></div>}}
            rowHeight={20}
            rowGetter={({index}) => this.rowGetter(index,orders)}
            rowCount={orders.length}
            rowClassName="vRow"
            width={width}
          >
            {this.props.c.ordID.show ? <Column
              label="Ord. ID"
              className="vCell"
              dataKey="orderID"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.secOrdID.show ? <Column
              label="Sec. Ord. ID"
              className="vCell"
              dataKey="secOrderID"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.origPrice.show ? <Column
              label="Orig. Prx"
              className="vCell"
              dataKey="origPrice"
              cellDataGetter={({rowData}) => F.formatPrice(rowData.origPrice,{decimals: rowData.decimals})}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.newPrice.show ? <Column
              label="New Prx"
              className="vCell"
              dataKey="newPrice"
              cellDataGetter={({rowData}) => F.formatPrice(rowData.newPrice,{decimals: rowData.decimals})}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.origQty.show ?<Column
              label="Orig. Qty"
              className="vCell"
              dataKey="origQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.origQty)}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.newQty.show ? <Column
              label="New Qty"
              className="vCell"
              dataKey="newQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.newQty)}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.origExp.show ? <Column
              label="Orig. Exp. Date"
              className="vCell"
              dataKey="origExp"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.newExp.show ? <Column
              label="New Exp. Date"
              className="vCell"
              dataKey="newExp"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.error.show ? <Column
              label="Error"
              className="vCell"
              dataKey="status"
              cellRenderer={({cellData}) => {
                return(
                  <span className="w-100">
                  <Tooltip content={F.formatErrorRevise(cellData)} useDefaultStyles={true}>
                    <div>{cellData === "OK" ? "" : cellData}</div>
                  </Tooltip>
                  </span>
                )
              }}
              width={1}
              flexGrow={1}
            /> : null}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

const mapStateToProps = state => {
  return{
    c: state.orderReviseColumns,
  }
}

export default connect(mapStateToProps,null)(RevisedOrders);
