import React from 'react';
import Draggable from 'react-draggable';
import { Button, InputGroup, Form, Table, Dropdown, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BestPriceCalc from '../../utils/BestPriceCalc';
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { View } from 'react-native';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ConfirmationService from '../../services/ConfirmationService';
import { connect } from 'react-redux';
import { theme } from '../../utils/Theme';
import * as actions from '../../actions';
import QtySpinner from '../../components/QtySpinner/QtySpinner';

import Formatter from '../../utils/Formatter';
import CustomDate from '../../components/CustomDate/CustomDate';
import style from './OrderTicket.css';

class OrderTicket extends React.Component {
  constructor(props) {
    super(props);
    this.mkt = this.props.data.mkt;
    let price = (this.props.data.side === "BUY")
      ? BestPriceCalc.bestPriceBuy(this.props.data.mktPrx)
      : BestPriceCalc.bestPriceSell(this.props.data.mktPrx)
    price = (price === 0) ? this.mkt.priceMinIncrement : price;
    let side = (this.props.data.side === "BUY") ? 1 : 2;
    this.state = {
      qty: this.mkt.qtyMinClipSize,
      price: Formatter.formatPrice(price,this.mkt),
      side: side,
      type: 2,
      maxShow: this.mkt.qtyMinClipSize,
      tif: 1,
      iceberg: false,
      minQty: 0,
      date: this.addDays(new Date(),1),
      errPrice: false,
      errQty: false,
      errMaxShow: false,
      errMinQty: false,
      errStopPx: false,
      lowQty: this.mkt.qtyMinClipSize,
      lowShow: this.mkt.qtyMinClipSize,
      lowMinQty: 0,
      stopPx: Formatter.formatPrice(price,this.mkt),
      expand: false,
    }
  }
  pxComma = 0;
  stopPxComma = 0;

  priceIncrease = () => {
    if(this.state.type === 1 || this.state.type === 3)
      return;
    this.setState({errPrice: false});
    let prx = Number(this.stripPrice()) + this.mkt.priceMinIncrement;
    if(!Number.isInteger(prx/this.mkt.priceMinIncrement))
      prx = prx - prx % this.mkt.priceMinIncrement;
    this.setState({price: Formatter.formatPrice(prx,this.mkt)});
  }

  priceDecrease = () => {
    if(this.state.type === 1 || this.state.type === 3)
      return;
    this.setState({errPrice: false});
    if(Number(this.stripPrice()) - this.mkt.priceMinIncrement < this.mkt.priceMinIncrement){
      this.setState({price: Formatter.formatPrice(this.mkt.priceMinIncrement,this.mkt)});
      return;
    }
    let prx = Number(this.stripPrice()) - this.mkt.priceMinIncrement;
    if(!Number.isInteger(prx/this.mkt.priceMinIncrement))
      prx = prx + this.mkt.priceMinIncrement - prx % this.mkt.priceMinIncrement;
    this.setState({price: Formatter.formatPrice(prx,this.mkt)});
  }

  stopPriceIncrease = () => {
    this.setState({errStopPx: false});
    let prx = Number(this.stripStopPrice()) + this.mkt.priceMinIncrement;
    if(!Number.isInteger(prx/this.mkt.priceMinIncrement))
      prx = prx - prx % this.mkt.priceMinIncrement;
    this.setState({stopPx: Formatter.formatPrice(prx,this.mkt)});
  }

  stopPriceDecrease = () => {
    this.setState({errStopPx: false});
    if(Number(this.stripStopPrice()) - this.mkt.priceMinIncrement < this.mkt.priceMinIncrement){
      this.setState({stopPx: Formatter.formatPrice(this.mkt.priceMinIncrement,this.mkt)});
      return;
    }
    let prx = Number(this.stripStopPrice()) - this.mkt.priceMinIncrement;
    if(!Number.isInteger(prx/this.mkt.priceMinIncrement))
      prx = prx + this.mkt.priceMinIncrement - prx % this.mkt.priceMinIncrement;
    this.setState({stopPx: Formatter.formatPrice(prx,this.mkt)});
  }

  toggleSide = () => {
    if(this.state.side === 1)
      this.setState({side: 2});
    else
      this.setState({side: 1});
  }

  setType = (v) => {
    this.setState({type: v});
    if(v === 1 || v === 3)
      this.setTif(0);
  }

  setTif = (v) => {
    if(this.state.type === 1)
      return;
    if(this.state.type === 3){
      if(v > 1)
        return;
    }
    this.setState({tif: v});
  }

  onIcebergChange = () => {
    if(this.state.iceberg)
      this.setState({lowQty: this.mkt.qtyMinClipSize});
    else
      this.setState({lowQty: this.state.maxShow});
    this.setState({iceberg: !this.state.iceberg});
  }

  onDateChange = (value) => {
    this.setState({date: value});
  }

  onQtyChange = (v) => {
    this.setState({qty: v});
  }

  onQtyError = (e) => {
    this.setState({errQty: e});
  }

  stripQty = () => {
    return [...this.state.qty.toString()].filter(el => el !== ',').join("");
  }

  onMaxShowChange = (v) => {
    this.setState({maxShow: v});
    let sv = Number([...v.toString()].filter(el => el !== ',').join(''));
    if(sv < this.mkt.qtyMinClipSize)
      this.setState({lowQty: this.mkt.qtyMinClipSize});
    else
      this.setState({lowQty: Formatter.formatQty(sv+this.mkt.qtyMinIncrement)});
  }

  onMaxShowError = (e) => {
    this.setState({errMaxShow: e});
  }

  stripMaxShow = () => {
    return [...this.state.maxShow.toString()].filter(el => el !== ',').join("");
  }

  onMinQtyChange = (v) => {
    this.setState({minQty: v});
    if(v < this.mkt.qtyMinClipSize)
      this.setState({lowQty: this.mkt.qtyMinClipSize});
    else
      this.setState({lowQty: v});
  }

  onMinQtyError = (e) => {
    this.setState({errMinQty: e});
  }

  stripMinQty = () => {
    return [...this.state.minQty.toString()].filter(el => el !== ',').join("");
  }

  onPriceChange = (evt) => {
    let value = evt.currentTarget.value;
    let dotNumber = (this.mkt.decimals === 0) ? 0 : 1;
    if([...value].filter(el => el === '.').length > dotNumber)
      return;
    let decimals = value.split('.');
    if(decimals[1] === undefined){}
    else if(decimals[1].length > this.mkt.decimals)
      return;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.pxComma)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    let prx = Formatter.formatQty([...decimals[0]].filter(el => el !== ",").join(""));
    commaNumber = [...prx].filter(el => el === ",").length;
    this.pxComma = commaNumber;
    let decPart = (decimals[1] === undefined) ? "" : (decimals[1] === "" ? "." : "."+decimals[1]);
    this.setState({price: prx + decPart});
  }

  onPriceBlur = () => {
    let prx = this.state.price.split(".");
    let intPart = Formatter.formatQty([...prx[0]].filter(el => el !== ",").join(""));
    if(intPart === undefined || Number(this.state.price) === 0){
      this.setState({price: Formatter.formatPrice(this.mkt.priceMinIncrement,this.mkt)})
      return;
    }
    let decPart = (prx[1] === undefined) ? "" : prx[1];
    let diff = (prx[1] === undefined) ? this.mkt.decimals : this.mkt.decimals - prx[1].length;
    for(let i=0;i<diff;i++){
      decPart = decPart + "0";
    }
    decPart = (this.mkt.decimals > 0) ? "." + decPart : decPart;
    let sprx = [...(intPart + decPart)].filter(el => el !== ',' && el !== '.').join("");
    if(!Number.isInteger(sprx/this.mkt.priceMinIncrement))
      this.setState({errPrice: true});
    this.setState({price: (intPart + decPart)});
  }

  onPriceFocus = () => {
    // let prx = [...this.state.price.toString()].filter(el => el !== ',');
    // this.setState({price: prx.join("")});
    this.setState({errPrice: false});
  }

  stripPrice = () => {
    return [...this.state.price.toString()].filter(el => el !== ',' && el !== '.').join("");
  }

  onStopPriceChange = (evt) => {
    let value = evt.currentTarget.value;
    let dotNumber = (this.mkt.decimals === 0) ? 0 : 1;
    if([...value].filter(el => el === '.').length > dotNumber)
      return;
    let decimals = value.split('.');
    if(decimals[1] === undefined){}
    else if(decimals[1].length > this.mkt.decimals)
      return;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.stopPxComma)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    let prx = Formatter.formatQty([...decimals[0]].filter(el => el !== ",").join(""));
    commaNumber = [...prx].filter(el => el === ",").length;
    this.stopPxComma = commaNumber;
    let decPart = (decimals[1] === undefined) ? "" : (decimals[1] === "" ? "." : "."+decimals[1]);
    this.setState({stopPx: prx + decPart});
  }

  onStopPriceBlur = () => {
    let prx = this.state.stopPx.split(".");
    let intPart = Formatter.formatQty([...prx[0]].filter(el => el !== ",").join(""));
    if(intPart === undefined || Number(this.state.stopPx) === 0){
      this.setState({stopPx: Formatter.formatPrice(this.mkt.priceMinIncrement,this.mkt)})
      return;
    }
    let decPart = (prx[1] === undefined) ? "" : prx[1];
    let diff = (prx[1] === undefined) ? this.mkt.decimals : this.mkt.decimals - prx[1].length;
    for(let i=0;i<diff;i++){
      decPart = decPart + "0";
    }
    decPart = (this.mkt.decimals > 0) ? "." + decPart : decPart;
    let sprx = [...(intPart + decPart)].filter(el => el !== ',' && el !== '.').join("");
    if(!Number.isInteger(sprx/this.mkt.priceMinIncrement))
      this.setState({errStopPx: true});
    this.setState({stopPx: (intPart + decPart)});
  }

  onStopPriceFocus = () => {
    // let prx = [...this.state.price.toString()].filter(el => el !== ',');
    // this.setState({price: prx.join("")});
    this.setState({errStopPx: false});
  }

  stripStopPrice = () => {
    return [...this.state.stopPx.toString()].filter(el => el !== ',' && el !== '.').join("");
  }

  onSubmit = () => {
    if(this.isSubmitDisabled())
      return;
    let date = this.formatDate();
    let ot = {
      "price": Number(this.stripPrice()),
      "side": this.state.side,
      "qty": Number(this.stripQty()),
      "type": this.state.type,
      "tif": this.state.tif,
      "maxShow": (this.state.iceberg === true && this.state.type === 2 && (this.state.tif < 2 || this.state.tif > 5) ? Number(this.stripMaxShow()) : undefined),
      "minQty": (this.state.type === 2 && this.state.tif === 3 ? Number(this.stripMinQty()) : 0),
      "expireDate": (this.state.type === 2 && this.state.tif === 6 ? date : null),
      'stopPx': (this.state.type === 4 || this.state.type === 3) ? Number(this.stripStopPrice()) : 0,
    }
    const yesFunction = () => {
      this.props.close(this.props.data.id);
      this.props.submit(this.props.data.mkt,ot);
    };
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.submitConfirmation(yesFunction,toggle,
      {type: ot.type, side: ot.side, qty: this.state.qty, prx: this.state.price},
      this.mkt,this.props.confirmation.submit
    );
  }

  isSubmitDisabled = () => {
    if(!this.props.isAccountSelected || this.state.errPrice || this.state.errQty || this.state.errMaxShow || this.state.errMinQty)
      return true;
    return false;
  }

  formatDate() {
    let d = this.state.date.getDate().toString();
    let m = this.state.date.getMonth() + 1;
    m = m.toString();
    let y = this.state.date.getFullYear().toString();
    d = (d < 10) ? "0"+d : d;
    m = (m < 10) ? "0"+m : m;
    return y+m+d;
  }

  fixCoords(coords) {
    let width = window.innerWidth;
    if(width - coords.x <= 350)
      coords.x = width - 360;
    return coords;
  }

  addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  sideRadio = (side) => {
    return (this.state.side === side
      ? <i className="fas fa-dot-circle ml-1"></i>
      : <i class="far fa-circle ml-1"></i>)
  }

  typeRadio = (type) => {
    return (this.state.type === type
      ? <i className="fas fa-dot-circle ml-1"></i>
      : <i class="far fa-circle ml-1"></i>)
  }

  tifRadio = (tif) => {
    let color = {}
    if(this.state.type === 1 && tif !== 0)
      color = {color: 'grey'};
    if(this.state.type === 3 && tif > 1)
      color = {color: 'grey'};
    return (this.state.tif === tif
      ? <i className="fas fa-dot-circle ml-1" style={color}></i>
      : <i class="far fa-circle ml-1" style={color}></i>)
  }

  icebergCheck = () => {
    return(this.state.iceberg === true
      ? <i className="fas fa-check-square mr-1"></i>
      : <i className="far fa-square mr-1"></i>
    )
  }

  expandTicket = () => {
    this.setState({expand: !this.state.expand});
  }

  expandArrow = () => {
    if(this.state.expand)
      return <i class="fas fa-angle-double-up" onClick={this.expandTicket}></i>
    else
      return <i class="fas fa-angle-double-down" onClick={this.expandTicket}></i>
  }

  getHeight = () => {
    if(this.state.expand)
      return 610;
    else
      return 420;
  }

  render() {
    if(this.props.data === null)
      return <span></span>;
    let mkt = this.props.data.mkt;
    let bestPrice = this.state.price;
    let id = this.props.data.id;
    let coords = this.fixCoords(this.props.data.coords);
    return(
      <Draggable defaultPosition={{x:coords.x,y:coords.y}} bounds="html" cancel=".noHandle">
        <div className="ticketContainer d-flex flex-column justify-content-between"
          style={{backgroundColor:theme(this.props.theme,'body'),color:theme(this.props.theme,'text'),height:this.getHeight()}}>
          <div className="ticketContent">
            <div className="d-flex justify-content-between">
              <h3>{mkt.currencyBase}/{mkt.currencyTerm}</h3>
              <h3 onClick={() => this.props.close(id)} className="closeBtn">
                <i class="fa fa-window-close" aria-hidden="true"></i>
              </h3>
            </div>
            <div>
              <div className="radioRow">
                <div className="sideCol">
                  Side
                  <div className="radioItemCol" onClick={this.toggleSide}>
                    Buy{this.sideRadio(1)}
                  </div>
                  <div className="radioItemCol" onClick={this.toggleSide}>
                    Sell{this.sideRadio(2)}
                  </div>
                </div>
                <div className="typeCol">
                  Type
                  <div className="radioItemCol" onClick={() => this.setType(1)}>
                    Market{this.typeRadio(1)}
                  </div>
                  <div className="radioItemCol" onClick={() => this.setType(2)}>
                    Limit{this.typeRadio(2)}
                  </div>
                  <div className="radioItemCol" onClick={() => this.setType(3)}>
                    Stop(Market){this.typeRadio(3)}
                  </div>
                  <div className="radioItemCol" onClick={() => this.setType(4)}>
                    Stop(Limit){this.typeRadio(4)}
                  </div>
                </div>
                <div className="tifCol">
                  TIF
                  <div className="tifRow">
                    <div className="radioItemRow" onClick={() => this.setTif(1)}>
                      Gtc{this.tifRadio(1)}
                    </div>
                    <div className="radioItemRow" onClick={() => this.setTif(0)}>
                      Day{this.tifRadio(0)}
                    </div>
                  </div>
                  <div className="tifRow">
                    <div className="radioItemRow" onClick={() => this.setTif(6)}>
                      Gtd{this.tifRadio(6)}
                    </div>
                    <div className="radioItemRow" onClick={() => this.setTif(3)}>
                      Fak{this.tifRadio(3)}
                    </div>
                  </div>
                  <div className="tifRow">
                    <div className="radioItemRow" onClick={() => this.setTif(4)}>
                      Fok{this.tifRadio(4)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  <span style={{fontWeight:'bold'}}>Price</span>
                  <View className="noHandle" style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <View className="mr-1" style={{flex:3,justifyContent:'center',alignItems:'center',height:'100%'}}>
                      <input type="text" className={(this.state.errPrice ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                        value={this.state.price} onBlur={this.onPriceBlur} onFocus={this.onPriceFocus}
                        onChange={this.onPriceChange} disabled={(this.state.type === 1 || this.state.type === 3) ? "disabled" : ""}
                      />
                    </View>
                    <View style={{flex:0.5}}>
                      <span className={"d-flex flex-column"}>
                        <i className="fas fa-angle-up" onClick={this.priceIncrease}></i>
                        <i className="fas fa-angle-down" onClick={this.priceDecrease}></i>
                      </span>
                    </View>
                  </View>
                </div>
                <div className="inputCol">
                  <span style={{fontWeight:'bold'}}>Quantity</span>
                  <QtySpinner
                    containerStyle={"noHandle"}
                    onChange={this.onQtyChange}
                    onError={this.onQtyError}
                    step={this.mkt.qtyMinIncrement}
                    min={this.state.lowQty}
                    value={this.state.qty}
                    err={this.state.errQty}
                  />
                  {(this.state.type === 2 || this.state.type === 4) && (this.state.tif < 2 || this.state.tif > 5) ?
                  <div className="inputRow" onClick={this.onIcebergChange}>
                    {this.icebergCheck()}Iceberg
                  </div> : null}
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  {(this.state.type === 2 || this.state.type === 4) && this.state.tif === 6 ? <span style={{fontWeight:'bold'}}>Date
                    <DatePicker
                      customInput={<CustomDate/>}
                      selected={this.state.date}
                      onChange={this.onDateChange}
                      minDate={this.addDays(new Date(),1)}
                    />
                  </span> : null}
                  {(this.state.type === 4 || this.state.type === 3) ? <span><span style={{fontWeight:'bold'}}>Stop Price</span>
                  <View className="noHandle" style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <View className="mr-1" style={{flex:3,justifyContent:'center',alignItems:'center',height:'100%'}}>
                      <input type="text" className={(this.state.errStopPx ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                        value={this.state.stopPx} onBlur={this.onStopPriceBlur} onFocus={this.onStopPriceFocus}
                        onChange={this.onStopPriceChange}
                      />
                    </View>
                    <View style={{flex:0.5}}>
                      <span className={"d-flex flex-column"}>
                        <i className="fas fa-angle-up" onClick={this.stopPriceIncrease}></i>
                        <i className="fas fa-angle-down" onClick={this.stopPriceDecrease}></i>
                      </span>
                    </View>
                  </View></span>: null}
                </div>
                <div className="inputCol">
                  {(this.state.type === 2 || this.state.type == 4) && this.state.tif === 3 ? <span style={{fontWeight:'bold'}}>Min Qty
                  <QtySpinner
                    containerStyle={"noHandle"}
                    onChange={this.onMinQtyChange}
                    onError={this.onMinQtyError}
                    step={this.mkt.qtyMinIncrement}
                    min={this.state.lowMinQty}
                    max={this.stripQty()}
                    value={this.state.minQty}
                    err={this.state.errMinQty}
                  />
                  </span> : null}
                  {this.state.iceberg === true && (this.state.type === 2 || this.state.type === 4) && (this.state.tif < 2 || this.state.tif > 5)
                    ? <span style={{fontWeight:'bold'}}>Max Show
                  <QtySpinner
                    containerStyle={"noHandle"}
                    onChange={this.onMaxShowChange}
                    onError={this.onMaxShowError}
                    step={this.mkt.qtyMinIncrement}
                    min={this.state.lowShow}
                    max={this.stripQty() - this.mkt.qtyMinIncrement}
                    value={this.state.maxShow}
                    err={this.state.errMaxShow}
                  />
                  </span> : null}
                </div>
              </div>
              {this.state.expand ? <div className="inputRow">
                <div className="inputCol">
                  <div>Qty</div>
                  <QtySpinner
                    containerStyle={'noHandle'}
                    value={300}
                  />
                </div>
                <div className="inputCol">
                  <div>Buy</div>
                  <div className="text-center">Prx</div>
                </div>
                <div className="inputCol">
                  <div>Sell</div>
                  <div className="text-center">Prx</div>
                </div>
                <div className="inputCol">
                  <div>Qty</div>
                  <QtySpinner
                    containerStyle={'noHandle'}
                    value={300}
                  />
                </div>
              </div> : null}
            </div>
          </div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="expandRow">
              {this.expandArrow()}
            </div>
            <div className="w-100 d-flex justify-content-end">
              <div className="successButton" onClick={this.onSubmit}>SUBMIT</div>
            </div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return{
    confirmation: state.confirmation,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(OrderTicket);
