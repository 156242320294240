import React from 'react';
import F from '../../utils/Formatter';
import { connect } from 'react-redux';
import { theme } from '../../utils/Theme';
import { Column, Table, AutoSizer } from 'react-virtualized';

class Trades extends React.Component {
  constructor(props){
    super(props);
  }

  rowGetter = (index,orders) => {
    return orders[index];
  }

  render() {
    let orders = this.props.trades;
    return(
      <AutoSizer>
        {({width,height}) => (
          <Table
            headerHeight={23}
            headerClassName="vHead"
            height={height-33}
            noRowsRenderer={() => {return <div></div>}}
            rowHeight={23}
            rowGetter={({index}) => this.rowGetter(index,orders)}
            rowCount={orders.length}
            rowClassName="vRow"
            width={width}
          >
            {this.props.c.side.show ? <Column
              label="Side"
              className="vCell msideCell"
              dataKey="side"
              cellRenderer={({cellData}) => {
                let side = (cellData == 1) ? "BUY" : "SELL";
                let bgColor = (side === "BUY") ? theme(this.props.theme,'buy') : theme(this.props.theme,'sell');
                return(
                  <div className={"sideCell"} style={{color:bgColor}}>{side}</div>
                )
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.product.show ? <Column
              label="Product"
              className="vCell"
              dataKey="product"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.orderID.show ? <Column
              label="Ord. ID"
              className="vCell"
              dataKey="orderID"
              width={1}
              flexGrow={2.2}
            /> : null}
            {this.props.c.secOrderID.show ? <Column
              label="Sec. Ord. ID"
              className="vCell"
              dataKey="secOrderID"
              width={1}
              flexGrow={2.2}
            /> : null}
            {this.props.c.tradeID.show ? <Column
              label="Trade ID"
              className="vCell"
              dataKey="tradeID"
              width={1}
              flexGrow={0.8}
            /> : null}
            {this.props.c.execID.show ? <Column
              label="Exec ID"
              className="vCell"
              dataKey="execID"
              width={1}
              flexGrow={0.8}
            /> : null}
            {this.props.c.transactTime.show ? <Column
              label="Trans. Time"
              className="vCell"
              dataKey="transactTime"
              cellDataGetter={({rowData}) => F.formatDate(rowData.transactTime)}
              width={1}
              flexGrow={2}
            /> : null}
            {this.props.c.lastPx.show ? <Column
              label="Last Px"
              className="vCell"
              dataKey="lastPx"
              cellDataGetter={({rowData}) => F.formatPrice(rowData.lastPx,{decimals: rowData.decimals})}
              width={1}
              flexGrow={2}
            /> : null}
            {this.props.c.lastQty.show ? <Column
              label="Last Qty"
              className="vCell"
              dataKey="lastQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.lastQty)}
              width={1}
              flexGrow={1.8}
            /> : null}
            {this.props.c.cumQty.show ? <Column
              label="Cum. Qty"
              className="vCell"
              dataKey="cumQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.cumQty)}
              width={1}
              flexGrow={1.8}
            /> : null}
            {this.props.c.leavesQty.show ? <Column
              label="Leaves Qty"
              className="vCell"
              dataKey="leavesQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.leavesQty)}
              width={1}
              flexGrow={1.8}
            /> : null}
            {this.props.c.capacity.show ? <Column
              label="Capacity"
              className="vCell"
              dataKey="capacity"
              cellDataGetter={({rowData}) => F.formatTradeCapacity(rowData.capacity)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.bic.show ? <Column
              label="BIC"
              className="vCell"
              dataKey="account"
              width={1}
              flexGrow={2}
            /> : null}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

const mapStateToProps = state => {
  return{
    theme: state.theme,
    c: state.tradeColumns,
  }
}

export default connect(mapStateToProps,null)(Trades);
