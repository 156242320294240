const initial = {
  partial: false,
  lmt: false,
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_ALERT":
      settings[action.payload.username].alert = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_ALERT":
      if(settings[action.payload].alert === undefined){
        settings[action.payload].alert = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].alert;
    default:
      return state;
  }
}
