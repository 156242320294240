import React from 'react';

let defaultPrice = 0;

class BestPriceCalc extends React.Component {
  static getPriceValue(mktPrx,type,side,prxQty,index,defVal = 0) {
    if(mktPrx === undefined)
      return defVal;
    if(mktPrx[type] === undefined)
      return defVal;
    let bestValue = (mktPrx[type][side].length <= index) ? defVal : mktPrx[type][side][index][prxQty];
    return bestValue;
  }
  static getScrnByRawPx(mktPrx,side,prx,defVal = 0) {
    if(mktPrx === undefined)
      return defVal;
    if(mktPrx[1] === undefined)
      return defVal;
    let scrnData = mktPrx[1][side].find(el => el.mdEntryPx === prx);
    if(scrnData === undefined)
      return defVal;
    return scrnData.mdEntrySize;
  }
  static bestPriceBuy(mktPrx){
    if(mktPrx === undefined)
      return defaultPrice;
    if(mktPrx[1] !== undefined){
      if(mktPrx[1]["buy"].length !== 0)
        return mktPrx[1]["buy"][0].mdEntryPx;
    }
    if(mktPrx[0] === undefined)
      return defaultPrice;
    let bestPrice = (mktPrx[0]["buy"].length === 0) ? defaultPrice : mktPrx[0]["buy"][0].mdEntryPx;
    return bestPrice;
  }
  static bestPriceSell(mktPrx){
    if(mktPrx === undefined)
      return defaultPrice;
    if(mktPrx[1] !== undefined){
      if(mktPrx[1]["sell"].length !== 0)
        return mktPrx[1]["sell"][0].mdEntryPx;
    }
    if(mktPrx[0] === undefined)
      return defaultPrice;
    let bestPrice = (mktPrx[0]["sell"].length === 0) ? defaultPrice : mktPrx[0]["sell"][0].mdEntryPx;
    return bestPrice;
  }
}

export default BestPriceCalc;
