const initial = "dark";

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_THEME":
      settings[action.payload.username].theme = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_THEME":
      if(settings[action.payload].theme === undefined){
        settings[action.payload].theme = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].theme;
    default:
      return state;
  }
}
