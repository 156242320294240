const updates = {
  2: function(username){
    let settings = JSON.parse(localStorage.getItem("settings"));
    settings[username].confirmation.submit = true;
    localStorage.setItem("settings",JSON.stringify(settings));
  },
  3: function(username){
    let settings = JSON.parse(localStorage.getItem("settings"));
    settings[username].confirmation.logout = true;
    localStorage.setItem("settings",JSON.stringify(settings));
  },
}

const applyVersionChange = (username,version) => {
  let func = updates[version];
  func(username);
}

export { applyVersionChange };
