import React from 'react';
import './QtySpinner.css';
import F from '../../utils/Formatter';

class QtySpinner extends React.Component {
  cn = 0;

  qtyIncrease = () => {
    this.props.onError(false);
    let qty = Number(this.strip(this.props.value)) + this.props.step;
    if(this.props.max !== undefined){
      if(qty > this.props.max){
        this.props.onChange(F.formatQty(this.props.max));
        return;
      }
    }
    if(!Number.isInteger(qty/this.props.step))
      qty = qty - qty % this.props.step;
    this.props.onChange(F.formatQty(qty));
  }

  qtyDecrease = () => {
    this.props.onError(false);
    if(Number(this.strip(this.props.value)) - this.props.step < this.strip(this.props.min)){
      this.props.onChange(this.props.min)
      return;
    }
    let qty = Number(this.strip(this.props.value)) - this.props.step;
    if(qty > this.props.max){
      this.props.onChange(this.props.max);
      return;
    }
    if(!Number.isInteger(qty/this.props.step))
      qty = qty + this.props.step - qty % this.props.step;
    this.props.onChange(F.formatQty(qty));
  }

  onChange = (evt) => {
    let value = evt.currentTarget.value;
    let cn = [...value].filter(el => el === ",").length;
    if(isNaN(value) && cn !== this.cn)
      return;
    if(isNaN(this.strip(value)))
      return;
    if([...value].filter(el => el === '.').length > 0)
      return;
    cn = [...value].filter(el => el === ",").length;
    this.cn = cn;
    this.props.onChange(F.formatQty(this.strip(value)));
  }

  onQtyBlur = () => {
    let strVal = this.strip(this.props.value);
    if(!Number.isInteger(strVal/this.strip(this.props.min)))
      this.props.onError(true);
    if(Number(strVal) < this.strip(this.props.min))
      this.props.onError(true);
    if(this.props.max !== undefined){
      if(Number(strVal) > this.props.max)
        this.props.onError(true);
    }
    this.props.onChange(F.formatQty(strVal));
  }

  onQtyFocus = () => {
    this.props.onError(false);
  }

  updateCn = () => {
    this.cn = [...this.props.value.toString()].filter(el => el === ',').length;
  }

  strip = (val) => {
    return [...val.toString()].filter(el => el !== ',').join('');
  }

  render() {
    this.updateCn();
    return(
      <div className={"inputContainer " + this.props.containerStyle}>
        <div className={"numberContainer"}>
          <input type="text" className={"customInput qtySpinnerStyle" + (this.props.err === true ? " text-danger" : "")}
            onChange={this.onChange} onBlur={this.onQtyBlur} onFocus={this.onQtyFocus}
            value={this.props.value}/>
        </div>
        <div className={"spinnerContainer " + this.props.spinnerStyle}>
          <i className="fas fa-angle-up" onClick={this.qtyIncrease}></i>
          <i className="fas fa-angle-down" onClick={this.qtyDecrease}></i>
        </div>
      </div>
    );
  }
}

export default QtySpinner;
