const scheme = {
  "dark": {
    bg: '#0c1c2c',
    body: '#091520',
    text: 'white',
    textSec: '#ffbf00',
    textPri: 'rgb(27, 117, 188)',
    textBack: 'grey',
    input: 'customInput',
    screen: '#528212',
    buy: '#0072BB',
    sell: '#d2374a',
  },
  "light": {
    bg: '#fdfcf6',
    body: 'white',
    text: 'black',
    textSec: '#9F000F',
    textPri: 'rgb(27, 117, 188)',
    textBack: 'grey',
    input: 'customInputDark',
    screen: '#b9bc11',
    buy: '#4ab0ce',
    sell: '#e8704f',
  },
}

const theme = (type,prop) => {
  if(scheme[type] === undefined)
    return scheme["dark"][prop];
  return scheme[type][prop];
}

export { theme };
