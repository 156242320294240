const initial = {
  side: {
    show: true,
    grow: 1,
  },
  product: {
    show: true,
    grow: 1,
  },
  orderID: {
    show: true,
    grow: 1,
  },
  secOrderID: {
    show: true,
    grow: 1,
  },
  tradeID: {
    show: true,
    grow: 1,
  },
  execID: {
    show: true,
    grow: 1,
  },
  transactTime: {
    show: true,
    grow: 1,
  },
  lastPx: {
    show: true,
    grow: 1,
  },
  lastQty: {
    show: true,
    grow: 1,
  },
  cumQty: {
    show: true,
    grow: 1,
  },
  leavesQty: {
    show: true,
    grow: 1,
  },
  capacity: {
    show: true,
    grow: 1,
  },
  bic: {
    show: true,
    grow: 1,
  },
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_TRADE_COLUMNS":
      settings[action.payload.username].tradeColumns = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_TRADE_COLUMNS":
      if(settings[action.payload].tradeColumns === undefined){
        settings[action.payload].tradeColumns = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].tradeColumns;
    default:
      return state;
  }
}
