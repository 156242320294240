import { combineReducers } from 'redux';
import QuoteAggregationReducer from './QuoteAggregationReducer';
import UserReducer from './UserReducer';
import ConfirmationReducer from './ConfirmationReducer';
import ToggleConfirmationReducer from './ToggleConfirmationReducer';
import AlertReducer from './AlertReducer';
import ThemeReducer from './ThemeReducer';
import LiveColumnReducer from './LiveColumnReducer';
import OrderHistoryColumnReducer from './OrderHistoryColumnReducer';
import OrderReviseColumnReducer from './OrderReviseColumnReducer';
import CpColumnReducer from './CpColumnReducer';
import CcyColumnReducer from './CcyColumnReducer';
import TradeColumnReducer from './TradeColumnReducer';

export default combineReducers({
  quoteAggregation: QuoteAggregationReducer,
  currentUser: UserReducer,
  confirmation: ConfirmationReducer,
  confirmationModal: ToggleConfirmationReducer,
  alert: AlertReducer,
  theme: ThemeReducer,
  liveColumns: LiveColumnReducer,
  orderHistoryColumns: OrderHistoryColumnReducer,
  orderReviseColumns: OrderReviseColumnReducer,
  cpColumns: CpColumnReducer,
  ccyColumns: CcyColumnReducer,
  tradeColumns: TradeColumnReducer,
});
