import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { theme } from '../../utils/Theme';
import './Settings.css';

class Settings extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      quoteSizeTIF: this.props.quoteAggregation.quoteSizeTIF,
      sacrifice: this.props.quoteAggregation.sacrifice,
      pullConfirmation: this.props.confirmation.pull,
      pullAllConfirmation: this.props.confirmation.pullAll,
      reviseConfirmation: this.props.confirmation.revise,
      submitConfirmation: this.props.confirmation.submit,
      logoutConfirmation: this.props.confirmation.logout,
      partialAlert: this.props.alert.partial,
      lmtAlert: this.props.alert.lmt,
      tab: 0,
      theme: this.props.theme,
    }
  }
  title = {
    0: <span><i class="fas fa-ellipsis-h"></i> Core Settings</span>,
    1: <span><i class="far fa-comment-alt"></i> Action Confirmations</span>,
    2: <span><i class="far fa-bell"></i> Sound Alerts</span>,
  }

  changeTab = (tab) => {
    this.setState({tab: tab});
  }

  toggleQuoteSizeTIF = () => {
    if(this.state.quoteSizeTIF === "MKT")
      this.setState({quoteSizeTIF:"FAK"});
    else
      this.setState({quoteSizeTIF:"MKT"});
  }

  togglePullConfirmation = () => {
    this.setState({pullConfirmation: !this.state.pullConfirmation});
  }

  togglePullAllConfirmation = () => {
    this.setState({pullAllConfirmation: !this.state.pullAllConfirmation});
  }

  toggleReviseConfirmation = () => {
    this.setState({reviseConfirmation: !this.state.reviseConfirmation})
  }

  toggleSubmitConfirmation = () => {
    this.setState({submitConfirmation: !this.state.submitConfirmation})
  }

  toggleLogoutConfirmation = () => {
    this.setState({logoutConfirmation: !this.state.logoutConfirmation})
  }

  togglePartialAlert = () => {
    this.setState({partialAlert: !this.state.partialAlert})
  }

  toggleLmtAlert = () => {
    this.setState({lmtAlert: !this.state.lmtAlert})
  }

  setTheme = (theme) => {
    this.setState({theme:theme});
  }

  onSacrificeChange = (evt) => {
    let value = evt.currentTarget.value;
    value = value === "" ? value : parseInt(value) ;
    if(!Number.isInteger(value) && value !== "")
      return;
    if(value > 100 || value < 0)
      return;
    this.setState({sacrifice: value});
  }

  apply = () => {
    this.props.UpdateQuoteAggregation({
      username: this.props.currentUser,
      data: {
        quoteSizeTIF: this.state.quoteSizeTIF,
        sacrifice: this.state.sacrifice,
      }
    });
    this.props.UpdateConfirmation({
      username: this.props.currentUser,
      data: {
        pull: this.state.pullConfirmation,
        pullAll: this.state.pullAllConfirmation,
        revise: this.state.reviseConfirmation,
        submit: this.state.submitConfirmation,
        logout: this.state.logoutConfirmation,
      }
    });
    this.props.UpdateAlert({
      username: this.props.currentUser,
      data: {
        partial: this.state.partialAlert,
        lmt: this.state.lmtAlert,
      }
    });
    this.props.UpdateTheme({
      username: this.props.currentUser,
      data: this.state.theme,
    })
    this.props.close();
  }

  render() {
    let quoteSizeToggle = (this.state.quoteSizeTIF === "MKT") ? "fas fa-toggle-on " : "fas fa-toggle-off ";
    let pullConfirmationToggle = (this.state.pullConfirmation) ? "fas fa-check-square " : "far fa-square ";
    let pullAllConfirmationToggle = (this.state.pullAllConfirmation) ? "fas fa-check-square " : "far fa-square ";
    let reviseConfirmationToggle = (this.state.reviseConfirmation) ? "fas fa-check-square " : "far fa-square ";
    let submitConfirmationToggle = (this.state.submitConfirmation) ? "fas fa-check-square " : "far fa-square ";
    let logoutConfirmationToggle = (this.state.logoutConfirmation) ? "fas fa-check-square " : "far fa-square ";
    let partialAlertToggle = (this.state.partialAlert) ? "fas fa-check-square " : "far fa-square ";
    let lmtAlertToggle = (this.state.lmtAlert) ? "fas fa-check-square " : "far fa-square ";
    return(
      <div className="settingsContainer" style={{backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
        <div className="sideBar">
          <div className={"sideBarItem " + (this.state.tab === 0 ? "selected" : "")} onClick={() => this.changeTab(0)}>
            <span><i class="fas fa-ellipsis-h"></i> Core</span></div>
          <div className={"sideBarItem " + (this.state.tab === 1 ? "selected" : "")} onClick={() => this.changeTab(1)}>
            <span><i class="far fa-comment-alt"></i> Confirmations</span></div>
          <div className={"sideBarItem " + (this.state.tab === 2 ? "selected" : "")} onClick={() => this.changeTab(2)}>
            <span><i class="far fa-bell"></i> Alerts</span></div>
        </div>
        <div className="settings">
          <div className="settingsContent">
            <div className="d-flex justify-content-between">
              <h4>{this.title[this.state.tab]}</h4>
              <h4 onClick={() => this.props.close()} className="closeBtn">
                <i class="fa fa-window-close" aria-hidden="true"></i>
              </h4>
            </div>
            {this.state.tab === 0 ?
            <div>
              <div className="font-weight-bold mb-1">Quote Size Aggregation</div>
              <div className="d-flex flex-row align-items-start w-75 mb-2">
                <div className="mr-2">
                  {this.state.quoteSizeTIF}
                  <i className={quoteSizeToggle + "ml-1 pointer"} onClick={this.toggleQuoteSizeTIF}></i>
                </div>
                {this.state.quoteSizeTIF === "FAK"
                  ? <span className="d-flex flex-row align-items-start">
                      <div className="text-secondary">Sacrifice (0-100)</div>
                      <input style={{width:35}} className="customInputDark ml-1 text-right" value={this.state.sacrifice} onChange={this.onSacrificeChange}/>
                    </span>
                  : null}
              </div>
              <div className="font-weight-bold mb-1">Theme</div>
              <div className="d-flex flex-row align-items-start mb-2">
                <div className="pointer d-flex flex-row pr-2" onClick={() => this.setTheme("dark")}>
                  Dark{this.state.theme === "dark" ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
                <div className="pointer d-flex flex-row pr-2" onClick={() => this.setTheme("light")}>
                  Light{this.state.theme === "light" ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
              </div>
            </div>
            : null }
            {this.state.tab === 1 ?
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={pullConfirmationToggle + "pointer"} onClick={this.togglePullConfirmation}></i>
                </div>
                <div>Pull</div>
              </div>
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={pullAllConfirmationToggle + "pointer"} onClick={this.togglePullAllConfirmation}></i>
                </div>
                <div>Pull All</div>
              </div>
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={reviseConfirmationToggle + "pointer"} onClick={this.toggleReviseConfirmation}></i>
                </div>
                <div>Revise</div>
              </div>
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={submitConfirmationToggle + "pointer"} onClick={this.toggleSubmitConfirmation}></i>
                </div>
                <div>Submit</div>
              </div>
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={logoutConfirmationToggle + "pointer"} onClick={this.toggleLogoutConfirmation}></i>
                </div>
                <div>Log out</div>
              </div>
            </div>
            : null}
            {this.state.tab === 2 ?
            <div className="d-flex flex-column align-items-start">
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={partialAlertToggle + "pointer"} onClick={this.togglePartialAlert}></i>
                </div>
                <div>Partial Fill</div>
              </div>
              <div className="d-flex flex-row">
                <div className="mr-2">
                  <i className={lmtAlertToggle + "pointer"} onClick={this.toggleLmtAlert}></i>
                </div>
                <div>LMT Order Fill</div>
              </div>
            </div>
            : null}
          </div>
          <div className="w-100 d-flex justify-content-end">
            <div className="successButton" onClick={this.apply}>SAVE</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    currentUser: state.currentUser,
    quoteAggregation: state.quoteAggregation,
    confirmation: state.confirmation,
    alert: state.alert,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(Settings)
