import React from 'react';
import F from '../../utils/Formatter';
import { theme } from '../../utils/Theme';
import { connect } from 'react-redux';
import { Column, Table, AutoSizer } from 'react-virtualized';
import Tooltip from 'react-tooltip-lite';

class OrderHistory extends React.Component {
  constructor(props){
    super(props);
  }

  rowGetter = (index,orders) => {
    return orders[index];
  }

  genArray = (obj) => {
    const orders = Object.keys(obj).map(key => {
      // obj[key].key = key;
      return obj[key];
    });
    return orders;
  }

  render() {
    let orders = this.genArray(this.props.orderHistory);
    return(
      <AutoSizer>
        {({width,height}) => (
          <Table
            headerHeight={23}
            headerClassName="vHead"
            height={height-33}
            noRowsRenderer={() => {return <div></div>}}
            rowHeight={23}
            rowGetter={({index}) => this.rowGetter(index,orders)}
            rowCount={orders.length}
            rowClassName="vRow"
            width={width}
          >
            {this.props.c.side.show ? <Column
              label="Side"
              className="vCell msideCell"
              dataKey="side"
              cellRenderer={({cellData,rowIndex}) => {
                let side = (cellData == 1) ? "BUY" : "SELL";
                let bgColor = (side === "BUY") ? theme(this.props.theme,'buy') : theme(this.props.theme,'sell');
                return(
                  <div className={"sideCell"} style={{color:bgColor}}>{side}</div>
                )
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.product.show ? <Column
              label="Product"
              className="vCell"
              dataKey="product"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.price.show ? <Column
              label="Prx"
              className="vCell"
              dataKey="price"
              cellDataGetter={({rowData}) => F.formatPrice(rowData.price,{decimals: rowData.decimals})}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.stopPx.show ? <Column
              label="Stop Px"
              className="vCell"
              dataKey="stopPx"
              cellDataGetter={({rowData}) => F.formatPrice(rowData.stopPx,{decimals: rowData.decimals})}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.qty.show ? <Column
              label="Qty"
              className="vCell"
              dataKey="qty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.qty)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.cumQty.show ? <Column
              label="Traded (CumQty)"
              className="vCell"
              dataKey="cumQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.cumQty)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.remQty.show ? <Column
              label="Res. (RemQty)"
              className="vCell"
              dataKey="remQty"
              cellDataGetter={({rowData}) => F.formatQty(rowData.remQty)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.maxShow.show ? <Column
              label="Max Show"
              className="vCell"
              dataKey="maxShow"
              cellDataGetter={({rowData}) => F.formatQty(rowData.maxShow)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.type.show ? <Column
              label="Type"
              className="vCell"
              dataKey="type"
              cellDataGetter={({rowData}) => {
                return F.formatType(rowData.type)
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.tif.show ?<Column
              label="TIF"
              className="vCell"
              dataKey="timeInForce"
              cellDataGetter={({rowData}) => F.formatTIF(rowData.timeInForce)}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.bic.show ? <Column
              label="BIC"
              className="vCell"
              dataKey="account"
              width={1}
              flexGrow={2}
            /> : null}
            {this.props.c.ordTime.show ? <Column
              label="Ord. Time"
              className="vCell"
              dataKey="transactTime"
              cellDataGetter={({rowData}) => F.formatDate(rowData.transactTime)}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.status.show ? <Column
              label="Status"
              className="vCell"
              dataKey="status"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.error.show ? <Column
              label="Error"
              className="vCell"
              dataKey="rejection"
              cellRenderer={({cellData}) => {
                return(
                  <span className="w-100">
                  <Tooltip content={F.formatError(cellData)} useDefaultStyles={true}>
                    <div>{cellData}</div>
                  </Tooltip>
                  </span>
                )
              }}
              width={1}
              flexGrow={1.5}
            /> : null}
            {this.props.c.expDate.show ? <Column
              label="Exp. Date"
              className="vCell"
              dataKey="expireDate"
              width={1}
              flexGrow={1.5}
            /> : null}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

const mapStateToProps = state => {
  return{
    theme: state.theme,
    c: state.orderHistoryColumns,
  }
}

export default connect(mapStateToProps,null)(OrderHistory);
