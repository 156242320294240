import React from 'react';
import { View, Text } from 'react-native';
import { Dropdown, Button } from 'react-bootstrap';
import InputNumber from 'rc-input-number';
import Formatter from '../../utils/Formatter';
import { theme } from '../../utils/Theme';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ConfirmationService from '../../services/ConfirmationService';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import style from './OrderWidget.css';

class OrderWidget extends React.Component {
  constructor(props){
    super(props);
    this.market = this.props.selectedMarketArray[this.props.currentIndex];
    this.state = {
      orderPrice: Formatter.formatPrice(this.market.priceMinIncrement,this.market),
      orderQty: this.market.qtyMinClipSize,
      orderSide: 1,
      orderTimeInForce: 1,
      errPrice: false,
      errQty: false,
      errAggrQty: false,
      settings: false,
      ticket: true,
      bDay: true,
      aggrQty: this.market.qtyMinClipSize,
      submitHover: false,
      buyHover: false,
      sellHover: false,
    }
    this.onQtyChange = this.onQtyChange.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onSubmitOrder = this.onSubmitOrder.bind(this);
    this.toggleSide = this.toggleSide.bind(this);
    this.toggleTimeInForce = this.toggleTimeInForce.bind(this);
    this.priceIncrease = this.priceIncrease.bind(this);
    this.priceDecrease = this.priceDecrease.bind(this);
    this.qtyIncrease = this.qtyIncrease.bind(this);
    this.qtyDecrease = this.qtyDecrease.bind(this);
  }
  bestPrices = {
    "rawBuy": "N/A",
    "rawSell": "N/A",
    "scrnBuy": "N/A",
    "scrnSell": "N/A",
  }
  aggr = {
    buy: "N/A",
    sell: "N/A"
  }
  qtyComma = {
    orderQty: 0,
    aggrQty: 0,
  }
  pxComma = 0;

  componentDidUpdate() {
    let newMarket = this.props.selectedMarketArray[this.props.currentIndex];
    if(this.market.securityID !== newMarket.securityID){
      this.setState({orderQty: Formatter.formatQty(newMarket.qtyMinClipSize)});
      this.setState({aggrQty: Formatter.formatQty(newMarket.qtyMinClipSize)});
      this.setState({orderPrice: Formatter.formatPrice(newMarket.priceMinIncrement,newMarket)});
    }
    this.market = newMarket;
  }

  toggleSubmitHover = () => {
    this.setState({submitHover: !this.state.submitHover})
  }

  toggleBuyHover = () => {
    this.setState({buyHover: !this.state.buyHover})
  }

  toggleSellHover = () => {
    this.setState({sellHover: !this.state.sellHover})
  }

  toggleSettings = () => {
    this.setState({settings: !this.state.settings});
  }

  toggleBottomBar = () => {
    this.setState({ticket: !this.state.ticket});
  }

  toggleBDay = () => {
    this.setState({bDay: !this.state.bDay});
  }

  priceIncrease() {
    if(this.state.orderTimeInForce === 2)
      return;
    this.setState({errPrice: false});
    let prx = Number(this.stripPrice()) + this.market.priceMinIncrement;
    if(!Number.isInteger(prx/this.market.priceMinIncrement))
      prx = prx - prx % this.market.priceMinIncrement;
    this.setState({orderPrice: Formatter.formatPrice(prx,this.market)});
  }

  priceDecrease() {
    if(this.state.orderTimeInForce === 2)
      return;
    this.setState({errPrice: false});
    if(Number(this.stripPrice()) - this.market.priceMinIncrement < this.market.priceMinIncrement){
      this.setState({orderPrice: Formatter.formatPrice(this.market.priceMinIncrement,this.market)});
      return;
    }
    let prx = Number(this.stripPrice()) - this.market.priceMinIncrement;
    if(!Number.isInteger(prx/this.market.priceMinIncrement))
      prx = prx + this.market.priceMinIncrement - prx % this.market.priceMinIncrement;
    this.setState({orderPrice: Formatter.formatPrice(prx,this.market)});
  }

  qtyIncrease() {
    this.setState({errQty: false});
    let qty = Number(this.stripQty()) + this.market.qtyMinIncrement;
    if(!Number.isInteger(qty/this.market.qtyMinIncrement))
      qty = qty - qty % this.market.qtyMinIncrement;
    this.setState({orderQty: Formatter.formatQty(qty)});
  }

  qtyDecrease() {
    this.setState({errQty: false});
    if(Number(this.stripQty()) - this.market.qtyMinIncrement < this.market.qtyMinClipSize){
      this.setState({orderQty: this.market.qtyMinClipSize});
      return;
    }
    let qty = Number(this.stripQty()) - this.market.qtyMinIncrement;
    if(!Number.isInteger(qty/this.market.qtyMinIncrement))
      qty = qty + this.market.qtyMinIncrement - qty % this.market.qtyMinIncrement;
    this.setState({orderQty: Formatter.formatQty(qty)});
  }

  aggrQtyIncrease = () => {
    this.setState({errAggrQty: false});
    let qty = Number(this.stripAggrQty()) + this.market.qtyMinIncrement;
    if(!Number.isInteger(qty/this.market.qtyMinIncrement))
      qty = qty - qty % this.market.qtyMinIncrement;
    this.setState({aggrQty: Formatter.formatQty(qty)});
  }

  aggrQtyDecrease = () => {
    this.setState({errAggrQty: false});
    if(Number(this.stripAggrQty()) - this.market.qtyMinIncrement < this.market.qtyMinClipSize){
      this.setState({aggrQty: this.market.qtyMinClipSize});
      return;
    }
    let qty = Number(this.stripAggrQty()) - this.market.qtyMinIncrement;
    if(!Number.isInteger(qty/this.market.qtyMinIncrement))
      qty = qty + this.market.qtyMinIncrement - qty % this.market.qtyMinIncrement;
    this.setState({aggrQty: Formatter.formatQty(qty)});
  }

  toggleSide() {
    if(this.state.orderSide === 1){
      this.setState({orderSide: 2});
    }
    else{
      this.setState({orderSide: 1});
    }
  }

  toggleTimeInForce() {
    if(this.state.orderTimeInForce === 2)
      this.setState({orderTimeInForce: 0});
    else
      this.setState({orderTimeInForce: this.state.orderTimeInForce + 1});
  }

  onQtyChange(evt) {
    let value = evt.currentTarget.value;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.qtyComma.orderQty)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    if([...value].filter(el => el === '.').length > 0)
      return;
    let qty = Formatter.formatQty([...value].filter(el => el !== ",").join(""));
    commaNumber = [...qty].filter(el => el === ",").length;
    this.qtyComma.orderQty = commaNumber;
    this.setState({orderQty: qty});
  }

  onQtyBlur = () => {
    if(!Number.isInteger(this.stripQty()/this.market.qtyMinIncrement))
      this.setState({errQty: true});
    this.setState({orderQty: Formatter.formatQty(this.stripQty())});
  }

  onQtyFocus = () => {
    // this.setState({orderQty: this.stripQty()});
    this.setState({errQty: false});
  }

  onAggrQtyChange = (evt) => {
    let value = evt.currentTarget.value;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.qtyComma.aggrQty)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    if([...value].filter(el => el === '.').length > 0)
      return;
    let qty = Formatter.formatQty([...value].filter(el => el !== ",").join(""));
    commaNumber = [...qty].filter(el => el === ",").length;
    this.qtyComma.aggrQty = commaNumber;
    this.setState({aggrQty: qty});
  }

  onAggrQtyBlur = () => {
    if(!Number.isInteger(this.stripAggrQty()/this.market.qtyMinIncrement))
      this.setState({errAggrQty: true});
    this.setState({aggrQty: Formatter.formatQty(this.stripAggrQty())});
  }

  onAggrQtyFocus = () => {
    // this.setState({aggrQty: this.stripAggrQty()});
    this.setState({errAggrQty: false});
  }

  onPriceChange(evt) {
    let value = evt.currentTarget.value;
    let dotNumber = (this.market.decimals === 0) ? 0 : 1;
    if([...value].filter(el => el === '.').length > dotNumber)
      return;
    let decimals = value.split('.');
    if(decimals[1] === undefined){}
    else if(decimals[1].length > this.market.decimals)
      return;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.pxComma)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    let prx = Formatter.formatQty([...decimals[0]].filter(el => el !== ",").join(""));
    commaNumber = [...prx].filter(el => el === ",").length;
    this.pxComma = commaNumber;
    let decPart = (decimals[1] === undefined) ? "" : (decimals[1] === "" ? "." : "."+decimals[1]);
    this.setState({orderPrice: prx + decPart});
  }

  onPriceBlur = () => {
    let prx = this.state.orderPrice.split(".");
    let intPart = Formatter.formatQty([...prx[0]].filter(el => el !== ",").join(""));
    if(intPart === undefined || Number(this.state.orderPrice) === 0){
      this.setState({orderPrice: Formatter.formatPrice(this.market.priceMinIncrement,this.market)})
      return;
    }
    let decPart = (prx[1] === undefined) ? "" : prx[1];
    let diff = (prx[1] === undefined) ? this.market.decimals : this.market.decimals - prx[1].length;
    for(let i=0;i<diff;i++){
      decPart = decPart + "0";
    }
    decPart = (this.market.decimals > 0) ? "." + decPart : decPart;
    let sprx = [...(intPart + decPart)].filter(el => el !== ',' && el !== '.').join("");
    if(!Number.isInteger(sprx/this.market.priceMinIncrement))
      this.setState({errPrice: true});
    this.setState({orderPrice: (intPart + decPart)});
  }

  onPriceFocus = () => {
    // let prx = [...this.state.orderPrice.toString()].filter(el => el !== ',');
    // this.setState({orderPrice: prx.join("")});
    this.setState({errPrice: false});
  }

  stripPrice = () => {
    return [...this.state.orderPrice.toString()].filter(el => el !== ',' && el !== '.').join("");
  }

  stripQty = () => {
    return [...this.state.orderQty.toString()].filter(el => el !== ',').join("");
  }

  stripAggrQty = () => {
    return [...this.state.aggrQty.toString()].filter(el => el !== ',').join("");
  }

  onSubmitOrder() {
    let type = (this.state.orderTimeInForce === 2) ? 1 : 2;
    let tif = (this.state.orderTimeInForce === 2) ? 0 : this.state.orderTimeInForce;
    let newOrder = {
      "price": Number(this.stripPrice()),
      "side": this.state.orderSide,
      "qty": Number(this.stripQty()),
      "type": type,
      "timeInForce": tif,
    }
    const yesFunction = () => {
      this.props.quickOrderSubmit(newOrder,this.props.selectedMarketArray[this.props.currentIndex]);
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.submitConfirmation(yesFunction,toggle,
      {type: newOrder.type, side: newOrder.side, qty: this.state.orderQty, prx: this.state.orderPrice},
      this.market,this.props.confirmation.submit
    );
  }

  onSubmitAggregation(side) {
    if(this.state.errAggrQty)
      return;
    let type = (this.props.quoteAggregation.quoteSizeTIF === "MKT") ? 1 : 2;
    let tif = (type === 1) ? 0 : 3;
    let newOrder = {
      "price": 0,
      "side": side,
      "qty": Number(this.stripAggrQty()),
      "type": type,
      "timeInForce": tif,
    }
    let yesFunction;
    if(side === 1){
      if(this.aggr.sell === "N/A")
        return;
      if(tif === 3){
        let prx = Number([...this.aggr.sell.toString()].filter(el => el !== ',' && el !== '.').join(""));
        prx = (prx % this.market.priceMinIncrement !== 0) ? prx + this.market.priceMinIncrement - prx % this.market.priceMinIncrement : prx;
        prx = prx + this.props.quoteAggregation.sacrifice * this.market.priceMinIncrement;
        newOrder["price"] = prx;
      }
      yesFunction = () => {
        this.props.quickOrderSubmit(newOrder,this.props.selectedMarketArray[this.props.currentIndex]);
      }
    }
    if(side === 2){
      if(this.aggr.buy === "N/A")
        return;
      if(tif === 3){
        let prx = Number([...this.aggr.buy.toString()].filter(el => el !== ',' && el !== '.').join(""));
        prx = prx - prx % this.market.priceMinIncrement;
        prx = prx - this.props.quoteAggregation.sacrifice * this.market.priceMinIncrement;
        newOrder["price"] = prx;
      }
      yesFunction = () => {
        this.props.quickOrderSubmit(newOrder,this.props.selectedMarketArray[this.props.currentIndex]);
      }
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.submitConfirmation(yesFunction,toggle,
      {type: type, side: side, qty: this.state.aggrQty, prx: Formatter.formatPrice((side === 1 ? this.aggr.sell : this.aggr.buy),{decimals: this.market.decimals})},
      this.market,this.props.confirmation.submit,
    );
  }

  setBestPrices(mktPrx,mkt) {
    if(mktPrx === undefined)
      return;
    if(mktPrx[0] !== undefined){
      let rawBuy = (mktPrx[0]["buy"].length === 0) ? "N/A" : mktPrx[0]["buy"][0];
      rawBuy = (typeof rawBuy !== 'string') ? <div>
        {Formatter.formatPrice(rawBuy.mdEntryPx,mkt)}({Formatter.formatQty(rawBuy.mdEntrySize)})</div> : rawBuy;
      this.bestPrices["rawBuy"] = rawBuy;
      let rawSell = (mktPrx[0]["sell"].length === 0) ? "N/A" : mktPrx[0]["sell"][0];
      rawSell = (typeof rawSell !== 'string') ? <div>
        {Formatter.formatPrice(rawSell.mdEntryPx,mkt)}({Formatter.formatQty(rawSell.mdEntrySize)})</div> : rawSell;
      this.bestPrices["rawSell"] = rawSell;
    }
    if(mktPrx[1] !== undefined){
      let scrnBuy = (mktPrx[1]["buy"].length === 0) ? "N/A" : mktPrx[1]["buy"][0];
      scrnBuy = (typeof scrnBuy !== 'string') ? <div className="d-flex flex-column justify-content-center">
        {Formatter.formatPrice(scrnBuy.mdEntryPx,mkt,true)}({Formatter.formatQty(scrnBuy.mdEntrySize)})</div> : scrnBuy;
      this.bestPrices["scrnBuy"] = scrnBuy;
      let scrnSell = (mktPrx[1]["sell"].length === 0) ? "N/A" : mktPrx[1]["sell"][0];
      scrnSell = (typeof scrnSell !== 'string') ? <div className="d-flex flex-column justify-content-center">
        {Formatter.formatPrice(scrnSell.mdEntryPx,mkt,true)}({Formatter.formatQty(scrnSell.mdEntrySize)})</div> : scrnSell;
      this.bestPrices["scrnSell"] = scrnSell;
    }
  }

  setBusinesDay(bDays,secID) {
    let bDay = bDays.find(el => el.securityID === secID);
    if(bDay === undefined)
      return "---";
    let bDayArr = [...bDay.businessDay.toString()];
    let bDayFormatted = bDayArr.slice(0,4).join("") + "-" + bDayArr.slice(4,6).join("") + "-" + bDayArr.slice(6).join("");
    return bDayFormatted;
  }

  setMarketStats(stats,mkt) {
    let stat = stats.find(el => el.securityID === mkt.securityID);
    if(stat === undefined)
      return "N/A";
    if(stat.cumQty == 0 || stat.lastQty == 0)
      return "N/A";
    return (
      <span>
        <i class="fas fa-file-invoice-dollar"></i> {Formatter.formatPrice(stat.lastPx,mkt)}
        <i class="fas fa-sort-amount-up ml-1"></i> {Formatter.formatQty(stat.lastQty)}
      </span>
    )
  }

  aggregatePrice(mktPrx) {
    if(mktPrx === undefined)
      return;
    if(mktPrx[1] === undefined)
      return;
    let qty = this.stripAggrQty();
    let buy = mktPrx[1].buy;
    let sell = mktPrx[1].sell;
    let aggrB = { px: 0, qty: 0, found: false };
    let aggrS = { px: 0, qty: 0, found: false };
    for(let a = 0;a < buy.length;a++){
      let size = 0;
      if(buy[a].mdEntrySize + aggrB.qty >= qty){
        size = qty - aggrB.qty;
        aggrB.found = true;
      }
      else
        size = buy[a].mdEntrySize;
      aggrB.px += buy[a].mdEntryPx * size/qty;
      if(aggrB.found)
        break;
      aggrB.qty += buy[a].mdEntrySize;
    }
    for(let a = 0;a < sell.length;a++){
      let size = 0;
      if(sell[a].mdEntrySize + aggrS.qty >= qty){
        size = qty - aggrS.qty;
        aggrS.found = true;
      }
      else
        size = sell[a].mdEntrySize;
      aggrS.px += sell[a].mdEntryPx * size/qty;
      if(aggrS.found)
        break;
      aggrS.qty += sell[a].mdEntrySize;
    }
    if(aggrB.found)
      this.aggr.buy = Math.floor(aggrB.px);
    else
      this.aggr.buy = "N/A";
    if(aggrS.found)
      this.aggr.sell = Math.ceil(aggrS.px);
    else
      this.aggr.sell = "N/A";
  }

  render() {
    let newMarket = this.props.selectedMarketArray[this.props.currentIndex];
    let thisMarketPrice = this.props.marketPrice[newMarket.securityID];
    this.setBestPrices(thisMarketPrice,newMarket);
    if(!this.state.ticket)
      this.aggregatePrice(thisMarketPrice);
    let bDay = this.setBusinesDay(this.props.businessDayArray,newMarket.securityID);
    let stats = this.setMarketStats(this.props.marketStats,newMarket);
    let prxColor = (this.state.errPrice) ? "text-danger " : "";
    prxColor = (this.state.orderTimeInForce === 2) ? "text-secondary " : prxColor;
    let submitStyle = (!this.state.submitHover)
      ? {color:theme(this.props.theme,'screen'),border:`1px solid ${theme(this.props.theme,'screen')}`}
      : {color:'white',backgroundColor:theme(this.props.theme,'screen')}
    let aggrClassB = (this.state.errAggrQty) ? 'buyAgrDisabled' : 'buyAgr';
    let aggrClassS = (this.state.errAggrQty) ? 'buyAgrDisabled' : 'sellAgr';
    let aggrStyleB = {};
    let aggrStyleS = {};
    if(!this.state.errAggrQty){
      if(!this.state.buyHover)
        aggrStyleB = {color:theme(this.props.theme,'buy'),boxShadow:`0px 0px 0px 1px ${theme(this.props.theme,'buy')} inset`}
      else
        aggrStyleB = {color:'white',backgroundColor:theme(this.props.theme,'buy')}
      if(!this.state.sellHover)
        aggrStyleS = {color:theme(this.props.theme,'sell'),boxShadow:`0px 0px 0px 1px ${theme(this.props.theme,'sell')} inset`}
      else
        aggrStyleS = {color:'white',backgroundColor:theme(this.props.theme,'sell')}
    }
    return (
      <View style={styles.orderContainer} className="orderContainer">
        <div className="d-flex align-items-center justify-content-between">
          <Dropdown className="d-flex align-items-center justify-content-start"
            onClick={() => {this.props.bringWidgetToFront(this.props.currentIndex)}}>
            <div className="d-flex align-items-center mr-1">
              <Dropdown.Toggle className="mr-auto buttonFont"
                style={{backgroundColor:theme(this.props.theme,'bg'),border:'none',color:theme(this.props.theme,'text')}}>
                {newMarket.currencyBase}/
                {newMarket.currencyTerm}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.props.marketDefArray.map(mkt=>{
                  return <Dropdown.Item onClick={() => {this.props.selectMarketCurrency(mkt,this.props.currentIndex)}}>{mkt.currencyBase}/{mkt.currencyTerm}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </div>
            <span className="d-flex flex-column">
              {this.state.bDay
                ? <div className="titleFont mr-2"><i class="fas fa-calendar-alt"></i> {bDay}</div>
                : <div className="titleFont">{stats}</div>
              }
            </span>
          </Dropdown>
          <div className="buttonFont nonDraggable">
            <i class="fas fa-cogs revise" onClick={this.toggleSettings}></i>
            {this.state.settings ? <div className="dropdownContent d-flex flex-column justify-content-start align-items-center">
              <div className="pt-1 mb-1 w-100 text-center" style={{backgroundColor:'#dcdfe5',fontWeight:'bold',color:'#29374a'}}>Settings</div>
              <div className="d-flex flex-row justify-content-around align-items-center w-100">
                <div className="pointer d-flex flex-row justify-content-end w-100 pr-2" style={{color:'#29374a'}} onClick={this.toggleBottomBar}>
                  Ticket{this.state.ticket ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
                <div className="pointer d-flex flex-row justify-content-end w-100 pr-2" style={{color:'#29374a'}} onClick={this.toggleBottomBar}>
                  Calc{!this.state.ticket ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
              </div>
              <div className="d-flex flex-row justify-content-around align-items-center w-100">
                <div className="pointer d-flex flex-row justify-content-end w-100 pr-2" style={{color:'#29374a'}} onClick={this.toggleBDay}>
                  BDay{this.state.bDay ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
                <div className="pointer d-flex flex-row justify-content-end w-100 pr-2" style={{color:'#29374a'}} onClick={this.toggleBDay}>
                  Stats{!this.state.bDay ? <i className="fas fa-dot-circle ml-1"></i> : <i class="far fa-circle ml-1"></i>}</div>
              </div>
            </div> : null}
          </div>
        </div>
        <View style={styles.bigRedBlueRowContainer} className="nonDraggable">
          <View className="mb-1 mr-1" style={styles.bigRedBlueContainer}>
            <View className="mb-1 text-light" style={{...styles.smallButton,backgroundColor:theme(this.props.theme,'buy')}}>
              <div
                className="w-100 h-100 d-flex justify-content-center align-items-center buyButton">
                {this.bestPrices["rawBuy"]}
              </div>
            </View>
            <View className="text-light" style={{...styles.bigButton,backgroundColor:theme(this.props.theme,'screen')}}>
              <div
                className="w-100 h-100 p-2 d-flex justify-content-center align-items-center screenButton"
                onDoubleClick={(e) => {this.props.onOpenOrderModal(newMarket.securityID,"BUY",e)}}>
                {this.bestPrices["scrnBuy"]}
              </div>
            </View>
          </View>
          <View className="mb-1 nonDraggable" style={styles.bigRedBlueContainer}>
            <View className="mb-1 text-light" style={{...styles.smallButton,backgroundColor:theme(this.props.theme,'sell')}}>
              <div
                className="w-100 h-100 d-flex justify-content-center align-items-center sellButton">
                {this.bestPrices["rawSell"]}
              </div>
            </View>
            <View className="text-light" style={{...styles.bigButton,backgroundColor:theme(this.props.theme,'screen')}}>
              <div
                className="w-100 h-100 p-2 d-flex justify-content-center align-items-center screenButton"
                onDoubleClick={(e) => {this.props.onOpenOrderModal(newMarket.securityID,"SELL",e)}}>
                {this.bestPrices["scrnSell"]}
              </div>
            </View>
          </View>
        </View>
        <View className="nonDraggable" style={styles.orderBottomBar}>
          { this.state.ticket ?
          <View className="text-light" style={styles.orderBottomBarItem}>
            <View className="mr-1" style={{flex:1.5,height:'100%'}}>
              <Button
                style={{backgroundColor:(this.state.orderSide === 1 ? theme(this.props.theme,'buy') : theme(this.props.theme,'sell')),height:'100%',border:'none'}}
                onClick={this.toggleSide} block><span className="buttonFont">{this.state.orderSide === 1 ? "B" : "S"}</span></Button>
            </View>
            <View className="mr-1" style={{flex:2,height:'100%'}}>
              <Button variant="outline-secondary" style={{height:'100%'}} onClick={this.toggleTimeInForce} block>
                <span className="buttonFont">{Formatter.formatTIF(this.state.orderTimeInForce)}</span></Button>
            </View>
            <View className="mr-1" style={{flex:3,alignItems:"center",justifyContent:"center",height:'100%'}}>
              <input type="text" className={prxColor + " " + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                onChange={this.onPriceChange} disabled={this.state.orderTimeInForce === 2 ? "disabled" : ""}
                onBlur={this.onPriceBlur} onFocus={this.onPriceFocus}
                value={this.state.orderPrice}/>
            </View>
            <View style={{flex:0.5,color:theme(this.props.theme,'text')}}>
              <span className={(this.state.orderTimeInForce === 2 ? "text-secondary " : "") + "d-flex flex-column"}>
                <i className="fas fa-angle-up" onClick={this.priceIncrease}></i>
                <i className="fas fa-angle-down" onClick={this.priceDecrease}></i>
              </span>
            </View>
            <View className="mr-1" style={{flex:3,alignItems:'center',justifyContent:'center',height:'100%'}}>
              <input type="text" className={(this.state.errQty ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                onChange={this.onQtyChange} onBlur={this.onQtyBlur} onFocus={this.onQtyFocus}
                value={this.state.orderQty}/>
            </View>
            <View style={{flex:0.5,color:theme(this.props.theme,'text')}}>
              <span className="d-flex flex-column">
                <i className="fas fa-angle-up" onClick={this.qtyIncrease}></i>
                <i className="fas fa-angle-down" onClick={this.qtyDecrease}></i>
              </span>
            </View>
            <View className="" style={{flex:1.5,height:'100%'}}>
              <div className="submitButton" onClick={this.onSubmitOrder} onMouseOver={this.toggleSubmitHover} onMouseOut={this.toggleSubmitHover}
                style={submitStyle}>
                <span className="buttonFont"><i class="fas fa-sign-in-alt"></i></span>
              </div>
            </View>
          </View> :
          <View className="text-light" style={styles.orderBottomBarItem}>
            <View className="mr-1" style={{flex:3.75,height:'100%',justifyContent:'center',alignItems:'center'}}>
              <div className={"w-100 h-100 d-flex align-items-center justify-content-center " + aggrClassB}
                onMouseOver={this.toggleBuyHover} onMouseOut={this.toggleBuyHover}
                style={{borderRadius:4,...aggrStyleB}} onDoubleClick={() => this.onSubmitAggregation(2)}
              >{Formatter.formatPrice(this.aggr.buy,{decimals:newMarket.decimals})}</div>
            </View>
            <View className="mr-1" style={{flex:3,alignItems:'center',justifyContent:'center',height:'100%'}}>
              <input type="text" className={(this.state.errAggrQty ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                onChange={this.onAggrQtyChange} onBlur={this.onAggrQtyBlur} onFocus={this.onAggrQtyFocus}
                value={this.state.aggrQty}/>
            </View>
            <View style={{flex:0.5,color:theme(this.props.theme,'text')}}>
              <span className="d-flex flex-column">
                <i className="fas fa-angle-up" onClick={this.aggrQtyIncrease}></i>
                <i className="fas fa-angle-down" onClick={this.aggrQtyDecrease}></i>
              </span>
            </View>
            <View className="" style={{flex:3.75,height:'100%',justifyContent:'center',alignItems:'center'}}>
              <div className={"w-100 h-100 d-flex align-items-center justify-content-center " + aggrClassS}
                onMouseOver={this.toggleSellHover} onMouseOut={this.toggleSellHover}
                style={{borderRadius:4,...aggrStyleS}} onDoubleClick={() => this.onSubmitAggregation(1)}
              >{Formatter.formatPrice(this.aggr.sell,{decimals:newMarket.decimals})}</div>
            </View>
          </View>
          }
        </View>
      </View>
    )
  }
}

const mapStateToProps = state => {
  return{
    quoteAggregation: state.quoteAggregation,
    confirmation: state.confirmation,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(OrderWidget);

const styles = {
  orderContainer : {
    flex:1,
    flexDirection:'column',
    height:'100%'
  },
  orderHeaderContainer: {
    flex:2,
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: 'white',
  },
  bigRedBlueContainer: {
    flex:1,
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
  },
  orderBottomBar: {
    flex:2,
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'center',
  },
  bigButton: {
    flex:3,
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:4,
  },
  smallButton: {
    flex:1,
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:4,
  },
  orderBottomBarItem: {
    flex:1,
    flexDirection:'row',
    height:'100%',
    width:'100%',
    alignItems:'center',
    justifyContent:'center',
  },
  bigRedBlueRowContainer: {
    flex:6,
    flexDirection:'row',
  }
}
