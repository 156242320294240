const initial = {
  ordID: {
    show: true,
    grow: 1,
  },
  secOrdID: {
    show: true,
    grow: 1,
  },
  origPrice: {
    show: true,
    grow: 1,
  },
  newPrice: {
    show: true,
    grow: 1,
  },
  origQty: {
    show: true,
    grow: 1,
  },
  newQty: {
    show: true,
    grow: 1,
  },
  origExp: {
    show: true,
    grow: 1,
  },
  newExp: {
    show: true,
    grow: 1,
  },
  error: {
    show: true,
    grow: 1,
  }
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_ORDER_REVISE_COLUMNS":
      settings[action.payload.username].orderReviseColumns = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_ORDER_REVISE_COLUMNS":
      if(settings[action.payload].orderReviseColumns === undefined){
        settings[action.payload].orderReviseColumns = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].orderReviseColumns;
    default:
      return state;
  }
}
