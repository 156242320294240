import React from 'react';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import F from '../utils/Formatter';

class ConfirmationService extends React.Component {
  static submitConfirmation(yesFunction,toggle,order,mkt,confirmation){
    if(confirmation){
      let type = (order.type === 1) ? "MKT" : "LMT";
      let side = (order.side === 1) ? "BUY" : "SELL";
      let prx = (type === "LMT") ? ` @ ${order.prx}` : "";
      ConfirmationModal.setMsg(
        `Are you sure you want to submit a ${type} order for ${side} ${order.qty} QTY ${mkt.currencyBase}.${mkt.currencyTerm}${prx}?`);
      ConfirmationModal.setNoFunction(() => toggle());
      ConfirmationModal.setYesFunction(() => {
        yesFunction();
        toggle();
      });
      toggle();
    }
    else
      yesFunction();
  }

  static pullAllConfirmation(yesFunction,toggle,confirmation){
    if(confirmation){
      ConfirmationModal.setMsg("Please confirm you want to pull all orders?");
      ConfirmationModal.setNoFunction(() => toggle());
      ConfirmationModal.setYesFunction(() => {
        yesFunction();
        toggle();
      });
      toggle();
    }
    else
      yesFunction()
  }

  static logoutConfirmation(yesFunction,toggle,confirmation){
    if(confirmation){
      ConfirmationModal.setMsg("Are you sure you want to logout (all session orders will be pulled)?");
      ConfirmationModal.setNoFunction(() => toggle());
      ConfirmationModal.setYesFunction(() => {
        yesFunction();
        toggle();
      });
      toggle();
    }
    else
      yesFunction()
  }

  static pullConfirmation(yesFunction,toggle,data,skip,confirmation){
    if(confirmation && !skip){
      let side = (data.side == "1") ? "BUY" : "SELL";
      let type = (data.type === 1) ? "MKT" : "LMT";
      ConfirmationModal.setMsg(
        `Are you sure you want to pull ${side} ${type} ${F.formatQty(data.qty)} QTY ${data.product} @ ${F.formatPrice(data.price,{decimals:data.decimals})}?`);
      ConfirmationModal.setNoFunction(() => toggle());
      ConfirmationModal.setYesFunction(() => {
        yesFunction();
        toggle();
      });
      toggle();
    }
    else
      yesFunction()
  }

  static reviseConfirmation(yesFunction,toggle,data,confirmation){
    if(confirmation){
      let side = (data.order.side == "1") ? "BUY" : "SELL";
      let type = (data.order.type === 1) ? "MKT" : "LMT";
      ConfirmationModal.setMsg(
        `Are you sure you want to revise a ${type} order for ${side} ${data.qty} QTY ${data.order.product} @ ${data.prx}?`);
      ConfirmationModal.setNoFunction(() => toggle());
      ConfirmationModal.setYesFunction(() => {
        yesFunction();
        toggle();
      });
      toggle();
    }
    else
      yesFunction()
  }
}

export default ConfirmationService;
