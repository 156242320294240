const initial = {
  quoteSizeTIF: "MKT",
  sacrifice: 0,
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_QUOTE_AGGREGATION":
      settings[action.payload.username].quoteAggregation = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_QUOTE_AGGREGATION":
      if(settings[action.payload].quoteAggregation === undefined){
        settings[action.payload].quoteAggregation = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].quoteAggregation;
    default:
      return state;
  }
}
