import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './screens/Home/Home';
import Login from './screens/Login/Login'

class Routes extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      websocket: null,
    }
    this.handleHeartbeat = this.handleHeartbeat.bind(this);
    this.reopenWebSocket = this.reopenWebSocket.bind(this);
  }
  env = "wss://tde-alphatestenv.redirectme.net:25008";
  motherEnv = "wss://ade-alphenv.theworkpc.com:25008";

  handleHeartbeat(evt) {
    let websocket = this.state.websocket;
    let result = JSON.parse(evt.data)
    if(result.msgType === 17) {
      websocket.send(JSON.stringify({
        "msgType": 17,
        "tag": 12345,
      }));
    }
  }

  reopenWebSocket(motherEnv) {
    let websocket = null;
    if(motherEnv){
      websocket = new WebSocket(this.motherEnv);
    }
    else{
      websocket = new WebSocket(this.env);
    }
    websocket.addEventListener("message", this.handleHeartbeat);
    this.setState({websocket: websocket});
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" render={
          ({location})=><Login location={location} websocket={this.state.websocket} reopenWebSocket={this.reopenWebSocket}/>}
        />
        <Route exact path="/home" render={
          ({location})=> <Home location={location} websocket={this.state.websocket}/>}
        />
      </Switch>
    );
  }
}

export default Routes;
