import React from 'react';
import F from '../../utils/Formatter';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { connect } from 'react-redux';

class CpLimits extends React.Component {
  constructor(props){
    super(props);
  }

  rowGetter = (index,cpLimits) => {
    return cpLimits[index];
  }

  utilHeader = (cpLimits,decimals) => {
    let sum = {
      uNOP: 0,
      uGOP: 0,
    }
    cpLimits.forEach(el => {
      sum.uNOP += el.uNOP;
      sum.uGOP += el.uGOP;
    });
    return F.formatPrice(sum.uNOP,{decimals:decimals}) + " / " + F.formatPrice(sum.uGOP,{decimals:decimals});
  }

  render(){
    let cpLimits = (this.props.cpLimits === undefined) ? [] : this.props.cpLimits.entries;
    let ccy = (cpLimits.length === 0) ? null : this.props.ccyFmt.find(el => el.currency === this.props.account.currencyHome);
    let ccyFmt = (cpLimits.length === 0) ? null : F.ccySymbol(ccy.currency);
    return(
      <AutoSizer>
        {({width,height}) => (
          <Table
            headerHeight={20}
            headerClassName="vHead"
            height={height-30}
            noRowsRenderer={() => {return <div></div>}}
            rowHeight={20}
            rowGetter={({index}) => this.rowGetter(index,cpLimits)}
            rowCount={cpLimits.length}
            rowClassName="vRow"
            width={width}
          >
            {this.props.c.cpAccountID.show ? <Column
              label="Cp BIC ID"
              className="vCell"
              dataKey="cpAccountID"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.cpAccountName.show ? <Column
              label="Cp BIC Name"
              className="vCell"
              dataKey="cpName"
              cellDataGetter={({rowData}) => {
                let cp = this.props.account.entries.find(cp => cp.cpAccountID === rowData.cpAccountID);
                return (cp === undefined) ? "N/A" : cp.cpAccountName;
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.nop.show ? <Column
              label="NOP"
              className="vCell"
              dataKey="NOP"
              cellDataGetter={({rowData}) => {
                let nopLimit = rowData.isInfinity_NOP ? "∞" : rowData.NOP;
                return ccyFmt + " " + F.formatPrice(nopLimit,{decimals:ccy.decimals});
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.gop.show ? <Column
              label="GOP"
              className="vCell"
              dataKey="GOP"
              cellDataGetter={({rowData}) => {
                let gopLimit = rowData.isInfinity_GOP ? "∞" : rowData.GOP;
                return ccyFmt + " " + F.formatPrice(gopLimit,{decimals:ccy.decimals});
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.util.show ? <Column
              label={this.utilHeader(cpLimits,ccy.decimals)}
              className="vCell"
              dataKey="Util"
              cellDataGetter={({rowData}) => {
                return ccyFmt + " " + F.formatPrice(rowData.uNOP,{decimals:ccy.decimals}) + " / " +
                  ccyFmt + " " + F.formatPrice(rowData.uGOP,{decimals:ccy.decimals});
              }}
              width={1}
              flexGrow={2}
            /> : null}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

const mapStateToProps = state => {
  return{
    c: state.cpColumns,
  }
}

export default connect(mapStateToProps,null)(CpLimits);
