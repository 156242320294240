import React from 'react';
import Draggable from 'react-draggable';
import { View } from 'react-native';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Formatter from '../../utils/Formatter';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { theme } from '../../utils/Theme';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ConfirmationService from '../../services/ConfirmationService';
import './OrderRevise.css';


class OrderRevise extends React.Component {
  constructor(props){
    super(props);
    this.order = this.props.data.order;
    this.qty = (this.order.remQty === undefined) ? this.order.qty : this.order.remQty;
    this.state = {
      price: Formatter.formatPrice(this.order.price,this.order),
      qty: Formatter.formatQty(this.qty),
      stopPx: (this.order.stopPx > 0) ? Formatter.formatPrice(this.order.stopPx,this.order) : 0,
      errPrice: false,
      errQty: false,
      errStopPx: false,
    }
    this.pxComma = [...this.state.price.toString()].filter(el => el === ",").length;
    this.stopPxComma = [...this.state.stopPx.toString()].filter(el => el === ",").length;
  }
  pxComma = 0;
  stopPxComma = 0;

  priceIncrease = () => {
    this.setState({errPrice: false});
    let prx = Number(this.stripPrice()) + this.order.priceMinIncrement;
    if(!Number.isInteger(prx/this.order.priceMinIncrement))
      prx = prx - prx % this.order.priceMinIncrement;
    this.setState({price: Formatter.formatPrice(prx,this.order)});
  }

  priceDecrease = () => {
    this.setState({errPrice: false});
    if(Number(this.stripPrice()) - this.order.priceMinIncrement < this.order.priceMinIncrement){
      this.setState({price: Formatter.formatPrice(this.order.priceMinIncrement,this.order)});
      return;
    }
    let prx = Number(this.stripPrice()) - this.order.priceMinIncrement;
    if(!Number.isInteger(prx/this.order.priceMinIncrement))
      prx = prx + this.order.priceMinIncrement - prx % this.order.priceMinIncrement;
    this.setState({price: Formatter.formatPrice(prx,this.order)});
  }

  stopPriceIncrease = () => {
    this.setState({errStopPx: false});
    let prx = Number(this.stripStopPrice()) + this.order.priceMinIncrement;
    if(!Number.isInteger(prx/this.order.priceMinIncrement))
      prx = prx - prx % this.order.priceMinIncrement;
    this.setState({stopPx: Formatter.formatPrice(prx,this.order)});
  }

  stopPriceDecrease = () => {
    this.setState({errStopPx: false});
    if(Number(this.stripStopPrice()) - this.order.priceMinIncrement < this.order.priceMinIncrement){
      this.setState({stopPx: Formatter.formatPrice(this.order.priceMinIncrement,this.order)});
      return;
    }
    let prx = Number(this.stripPrice()) - this.order.priceMinIncrement;
    if(!Number.isInteger(prx/this.order.priceMinIncrement))
      prx = prx + this.order.priceMinIncrement - prx % this.order.priceMinIncrement;
    this.setState({stopPx: Formatter.formatPrice(prx,this.order)});
  }

  qtyIncrease = () => {
    this.setState({errQty: false});
    let qty = Number(this.stripQty()) + this.order.qtyMinIncrement;
    if(!Number.isInteger(qty/this.order.qtyMinIncrement))
      qty = qty - qty % this.order.qtyMinIncrement;
    this.setState({qty: Formatter.formatQty(qty)});
  }

  qtyDecrease = () => {
    this.setState({errQty: false});
    if(Number(this.stripQty()) - this.order.qtyMinIncrement < this.order.qtyMinClipSize){
      this.setState({qty: this.order.qtyMinClipSize});
      return;
    }
    let qty = Number(this.stripQty()) - this.order.qtyMinIncrement;
    if(!Number.isInteger(qty/this.order.qtyMinIncrement))
      qty = qty + this.order.qtyMinIncrement - qty % this.order.qtyMinIncrement;
    this.setState({qty: Formatter.formatQty(qty)});
  }

  onQtyChange = (evt) => {
    let value = evt.currentTarget.value;
    if(isNaN(value))
      return;
    this.setState({qty: value});
  }

  onQtyBlur = () => {
    if(!Number.isInteger(this.stripQty()/this.order.qtyMinIncrement))
      this.setState({errQty: true});
    this.setState({qty: Formatter.formatQty(this.state.qty)});
  }

  onQtyFocus = () => {
    this.setState({qty: this.stripQty()});
    this.setState({errQty: false});
  }

  onPriceChange = (evt) => {
    let value = evt.currentTarget.value;
    let dotNumber = (this.order.decimals === 0) ? 0 : 1;
    if([...value].filter(el => el === '.').length > dotNumber)
      return;
    let decimals = value.split('.');
    if(decimals[1] === undefined){}
    else if(decimals[1].length > this.order.decimals)
      return;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.pxComma)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    let prx = Formatter.formatQty([...decimals[0]].filter(el => el !== ",").join(""));
    commaNumber = [...prx].filter(el => el === ",").length;
    this.pxComma = commaNumber;
    let decPart = (decimals[1] === undefined) ? "" : (decimals[1] === "" ? "." : "."+decimals[1]);
    this.setState({price: prx + decPart});
  }

  onPriceBlur = () => {
    let prx = this.state.price.split(".");
    let intPart = Formatter.formatQty([...prx[0]].filter(el => el !== ",").join(""));
    if(intPart === undefined || Number(this.state.price) === 0){
      this.setState({price: Formatter.formatPrice(this.order.priceMinIncrement,this.order)})
      return;
    }
    let decPart = (prx[1] === undefined) ? "" : prx[1];
    let diff = (prx[1] === undefined) ? this.order.decimals : this.order.decimals - prx[1].length;
    for(let i=0;i<diff;i++){
      decPart = decPart + "0";
    }
    decPart = (this.order.decimals > 0) ? "." + decPart : decPart;
    let sprx = [...(intPart + decPart)].filter(el => el !== ',' && el !== '.').join("");
    if(!Number.isInteger(sprx/this.order.priceMinIncrement))
      this.setState({errPrice: true});
    this.setState({price: (intPart + decPart)});
  }

  onPriceFocus = () => {
    // let prx = [...this.state.price.toString()].filter(el => el !== ',');
    // this.setState({price: prx.join("")});
    this.setState({errPrice: false});
  }

  stripPrice = () => {
    return [...this.state.price.toString()].filter(el => el !== ',' && el !== '.').join("");
  }

  onStopPriceChange = (evt) => {
    let value = evt.currentTarget.value;
    let dotNumber = (this.order.decimals === 0) ? 0 : 1;
    if([...value].filter(el => el === '.').length > dotNumber)
      return;
    let decimals = value.split('.');
    if(decimals[1] === undefined){}
    else if(decimals[1].length > this.order.decimals)
      return;
    let commaNumber = [...value].filter(el => el === ",").length;
    if(isNaN(value) && commaNumber !== this.stopPxComma)
      return;
    if(isNaN([...value].filter(el => el !== ",").join("")))
      return;
    let prx = Formatter.formatQty([...decimals[0]].filter(el => el !== ",").join(""));
    commaNumber = [...prx].filter(el => el === ",").length;
    this.stopPxComma = commaNumber;
    let decPart = (decimals[1] === undefined) ? "" : (decimals[1] === "" ? "." : "."+decimals[1]);
    this.setState({stopPx: prx + decPart});
  }

  onStopPriceBlur = () => {
    let prx = this.state.stopPx.split(".");
    let intPart = Formatter.formatQty([...prx[0]].filter(el => el !== ",").join(""));
    if(intPart === undefined || Number(this.state.stopPx) === 0){
      this.setState({stopPx: Formatter.formatPrice(this.order.priceMinIncrement,this.order)})
      return;
    }
    let decPart = (prx[1] === undefined) ? "" : prx[1];
    let diff = (prx[1] === undefined) ? this.order.decimals : this.order.decimals - prx[1].length;
    for(let i=0;i<diff;i++){
      decPart = decPart + "0";
    }
    decPart = (this.order.decimals > 0) ? "." + decPart : decPart;
    let sprx = [...(intPart + decPart)].filter(el => el !== ',' && el !== '.').join("");
    if(!Number.isInteger(sprx/this.order.priceMinIncrement))
      this.setState({errStopPx: true});
    this.setState({stopPx: (intPart + decPart)});
  }

  onStopPriceFocus = () => {
    // let prx = [...this.state.price.toString()].filter(el => el !== ',');
    // this.setState({price: prx.join("")});
    this.setState({errStopPx: false});
  }

  stripStopPrice = () => {
    return [...this.state.stopPx.toString()].filter(el => el !== ',' && el !== '.').join("");
  }

  stripQty = () => {
    return [...this.state.qty.toString()].filter(el => el !== ',').join("");
  }

  onSubmit = () => {
    if(this.isReviseDisabled())
      return;
    const yesFunction = () => {
      this.props.close(this.props.data.id);
      let reviseOrder = {
        price: Number(this.stripPrice()),
        qty: Number(this.stripQty()),
        stopPx: Number(this.stripStopPrice()),
        orderID: this.props.data.key,
      }
      this.props.submit(reviseOrder);
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.reviseConfirmation(yesFunction,toggle,
      {qty: this.state.qty, prx: this.state.price, order: this.props.data.order},
      this.props.confirmation.revise
    );
  }

  isReviseDisabled = () => {
    if(this.state.errPrice || this.state.errQty)
      return true;
    return false;
  }

  getHeight = () => {
    if(this.order.stopPx > 0)
      return 230;
    else
      return 190;
  }

  render() {
    if(this.props.data === null)
      return <span></span>;
    let id = this.props.data.id;
    let coords = this.props.data.coords;
    return(
      <Draggable defaultPosition={{x:coords.x,y:coords.y}} bounds="html" cancel=".noHandle">
        <div className="reviseContainer d-flex flex-column justify-content-between"
          style={{backgroundColor:theme(this.props.theme,'body'),color:theme(this.props.theme,'text'),height:this.getHeight()}}>
          <div className="ticketContent">
            <div className="d-flex justify-content-between">
              <h3>Order Revise</h3>
              <h3 onClick={() => this.props.close(id)} className="closeBtn">
                <i class="fa fa-window-close" aria-hidden="true"></i>
              </h3>
            </div>
            <div>
              <div className="inputRow">
                <div className="inputCol">
                  <span style={{fontWeight:'bold'}}>Price</span>
                  <View className="noHandle" style={{flex:1,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <View className="mr-1" style={{flex:3,justifyContent:'center',alignItems:'center',height:'100%'}}>
                      <input type="text" className={(this.state.errPrice ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                        value={this.state.price} onBlur={this.onPriceBlur} onFocus={this.onPriceFocus}
                        onChange={this.onPriceChange} disabled={this.state.type === 1 ? "disabled" : ""}
                      />
                    </View>
                    <View style={{flex:0.5}}>
                      <span className={"d-flex flex-column"}>
                        <i className="fas fa-angle-up" onClick={this.priceIncrease}></i>
                        <i className="fas fa-angle-down" onClick={this.priceDecrease}></i>
                      </span>
                    </View>
                  </View>
                </div>
                <div className="inputCol">
                  <span style={{fontWeight:'bold'}}>Quantity</span>
                  <View className="noHandle" style={{flex:1,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <View className="mr-1" style={{flex:3,alignItems:'center',justifyContent:'center',height:'100%'}}>
                      <input type="text" className={(this.state.errQty ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                        onChange={this.onQtyChange} onBlur={this.onQtyBlur} onFocus={this.onQtyFocus}
                        value={this.state.qty}/>
                    </View>
                    <View style={{flex:0.5}}>
                      <span className="d-flex flex-column">
                        <i className="fas fa-angle-up" onClick={this.qtyIncrease}></i>
                        <i className="fas fa-angle-down" onClick={this.qtyDecrease}></i>
                      </span>
                    </View>
                  </View>
                </div>
              </div>
              <div className="inputRow">
                <div className="inputCol">
                  {this.order.stopPx > 0 ? <span><span style={{fontWeight:'bold'}}>Stop Price</span>
                  <View className="noHandle" style={{flex:1,flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                    <View className="mr-1" style={{flex:3,justifyContent:'center',alignItems:'center',height:'100%'}}>
                      <input type="text" className={(this.state.errStopPx ? "text-danger " : "") + theme(this.props.theme,'input') + " w-100 h-75 text-right"}
                        value={this.state.stopPx} onBlur={this.onStopPriceBlur} onFocus={this.onStopPriceFocus}
                        onChange={this.onStopPriceChange} disabled={this.state.type === 1 ? "disabled" : ""}
                      />
                    </View>
                    <View style={{flex:0.5}}>
                      <span className={"d-flex flex-column"}>
                        <i className="fas fa-angle-up" onClick={this.stopPriceIncrease}></i>
                        <i className="fas fa-angle-down" onClick={this.stopPriceDecrease}></i>
                      </span>
                    </View>
                  </View></span> : null}
                </div>
                <div className="inputCol">
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end">
            <div className="successButton" onClick={this.onSubmit}>REVISE</div>
          </div>
        </div>
      </Draggable>
    );
  }
}

const mapStateToProps = state => {
  return{
    confirmation: state.confirmation,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(OrderRevise);
