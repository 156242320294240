import React from 'react';
import Errors from './Errors';

class Formatter extends React.Component {
  static formatPrice(price,mkt,showPip = false) {
    if(isNaN(price))
      return price;
    if(price === "")
      return price;
    let fmtPrice = price.toString();
    let minus = false;
    if(fmtPrice.charAt(0) === "-"){
      fmtPrice = fmtPrice.substring(1);
      minus = true;
    }
    let newPrice = "";
    for(let i=fmtPrice.length-1;i>=fmtPrice.length - mkt.decimals;i--){
      let newChar = fmtPrice.charAt(i);
      if(i < 0){
        newChar = "0";
      }
      newPrice = newChar + newPrice;
      if(i === fmtPrice.length - mkt.decimals){
        newPrice = "." + newPrice;
      }
    }
    if(mkt.decimals >= fmtPrice.length){
      newPrice = "0" + newPrice;
    }
    else{
      let comaCounter = 0;
      for(let i=fmtPrice.length-mkt.decimals-1;i>=0;i--){
        comaCounter++;
        let newChar = fmtPrice.charAt(i);
        newPrice = newChar + newPrice;
        if(comaCounter === 3 && i !== 0){
          newPrice = "," + newPrice;
          comaCounter = 0;
        }
      }
      if(minus)
        newPrice = "-" + newPrice;
    }
    if(!showPip)
      return newPrice;
    let noPip = newPrice.slice(0,newPrice.length-mkt.decimalsPip-mkt.decimalsAfterPip);
    let pip = newPrice.slice(newPrice.length-mkt.decimalsAfterPip-mkt.decimalsPip,newPrice.length-mkt.decimalsAfterPip);
    let afterPip = newPrice.slice(newPrice.length-mkt.decimalsAfterPip,newPrice.length);
    return (
      <span>
        <span>{noPip}</span>
        <span className="pipFormat">{pip}</span>
        <span>{afterPip}</span>
      </span>
    )
  }

  static formatQty(qty) {
    if(qty === undefined)
      return;
    if(qty === "")
      return "";
    let fmtQty = qty.toString();
    let newQty = "";
    let comaCounter = 0;
    for(let i=fmtQty.length-1;i>=0;i--){
      comaCounter++;
      let newChar = fmtQty.charAt(i);
      newQty = newChar + newQty;
      if(comaCounter === 3 && i !== 0){
        newQty = "," + newQty;
        comaCounter = 0;
      }
    }
    return newQty;
  }

  static formatTIF(tif) {
    if(tif === undefined)
      return;
    let newTIF = "";
    switch(tif.toString()){
      case "0":
        newTIF = "DAY";
        break;
      case "1":
        newTIF = "GTC";
        break;
      case "2":
        newTIF = "MKT";
        break;
      case "3":
        newTIF = "FAK";
        break;
      case "4":
        newTIF = "FOK";
        break;
      case "6":
        newTIF = "GTD";
        break;
    }
    return newTIF;
  }

  static formatOBStatus(status) {
    if(status === undefined)
      return;
    let newStatus = "";
    switch(status.toString()){
      case "0":
        newStatus = "O";
        break;
      case "1":
        newStatus = "H";
        break;
      case "2":
        newStatus = "P";
        break;
      case "3":
        newStatus = "C";
        break;
    }
    return newStatus;
  }

  static formatTradeCapacity(cpty) {
    if(cpty === undefined)
      return;
    let newCpty = "";
    switch(cpty.toString()){
      case "1":
        newCpty = "TKR";
        break;
      case "2":
        newCpty = "MKR";
        break;
      case "3":
        newCpty = "N/A";
        break;
    }
    return newCpty;
  }

  static formatDate(dateMicros) {
    if(dateMicros === undefined)
      return "---";
    let time = new Date(dateMicros/1000);
    let day = time.getDate();
    let month = time.toLocaleString('en-us', { month: 'short' });
    let year = time.getFullYear().toString();
    let hour = time.getHours();
    let minute = time.getMinutes();
    let second = time.getSeconds();
    day = (day < 10) ? "0"+day : day;
    hour = (hour < 10) ? "0"+hour : hour;
    minute = (minute < 10) ? "0"+minute : minute;
    second = (second < 10) ? "0"+second : second;
    let datetime = day + " " + month + " " + year.slice(2) + " " + hour + ":" + minute + ":" + second;
    let date = (time.toUTCString() === "Invalid Date") ? "---" : datetime;
    return date;
  }

  static ccySymbol(ccy) {
    if(ccy === undefined)
      return "";
    let symbol = "";
    switch(ccy){
      case "USD":
        symbol = "$";
        break;
      case "EUR":
        symbol = "€";
        break;
      case "GBP":
        symbol = "£";
        break;
      case "JPY":
        symbol = "¥";
        break;
      case "CHF":
        symbol = "CHF";
        break;
      case "BTC":
        symbol = "BTC";
        break;
      case "LTC":
        symbol = "LTC";
        break;
      case "ZEC":
        symbol = "ZEC";
        break;
      case "XRP":
        symbol = "XRP";
        break;
      case "ETH":
        symbol = "ETH";
        break;
    }
    return symbol;
  }

  static formatError(errCode){
    if(!Errors[errCode])
      return "Unknown Error";
    else
      return Errors[errCode];
  }

  static formatErrorRevise(errCode){
    return Errors.revise[errCode];
  }

  static formatType(type){
    if(type === undefined)
      return "";
    let fType = "";
    switch (type.toString()) {
      case "1":
        fType = "MKT";
        break;
      case "2":
        fType = "LMT";
        break;
      case "3":
        fType = "STOP(MKT)";
        break;
      case "4":
        fType = "STOP(LMT)";
        break;
    }
    return fType;
  }

}

export default Formatter;
