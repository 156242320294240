import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Button, InputGroup, Form, Table, Dropdown, Row, Col, OverlayTrigger, Tooltip, ButtonGroup } from 'react-bootstrap';
import { View, Text, Picker } from 'react-native';
import Modal from 'react-responsive-modal';
import {Howl, Howler} from 'howler';
import produce from 'immer';

import style from './Home.css';
import Bootstrap from '../../utils/Bootstrap';
import FontAwesome from '../../utils/FontAwesome';
import Sounds from '../../utils/Sounds';
import { connect } from 'react-redux';
import * as actions from '../../actions';
/* Components */
import TopNavbar from '../../components/TopNavbar/TopNavbar';
import LiveSettings from '../../components/LiveSettings/LiveSettings';
import OrderWidget from '../../components/OrderWidget/OrderWidget';
import QuoteBoard from '../../components/QuoteBoard/QuoteBoard';
import OrderTicket from '../../components/OrderTicket/OrderTicket';
import OrderRevise from '../../components/OrderRevise/OrderRevise';
import LiveOrders from '../../components/LiveOrders/LiveOrders';
import OrderHistory from '../../components/OrderHistory/OrderHistory';
import Trades from '../../components/Trades/Trades';
import RevisedOrders from '../../components/RevisedOrders/RevisedOrders';
import CpLimits from '../../components/CpLimits/CpLimits';
import CcyLimits from '../../components/CcyLimits/CcyLimits';
import Settings from '../../components/Settings/Settings';
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal';
import ConfirmationService from '../../services/ConfirmationService';
/* Services */
import AccountService from '../../services/AccountService';
import Formatter from '../../utils/Formatter';
import BestPriceCalc from '../../utils/BestPriceCalc';
import LayoutService from '../../services/LayoutService';
import { theme } from '../../utils/Theme';

const ResponsiveGridLayout = WidthProvider(Responsive);
const fileDownload = require('js-file-download');
const sound = new Howl({
  src: [Sounds.chip],
});
console.log(sound);

class Home extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      layouts: LayoutService.initialLayout(this.props.currentUser),
      isAccountSelected: false,
      blinkSelectAccount: null,
      orderTab: 0,
      modal: false,
      settings: false,
      selectedAccount: null,
      previousSelectedAccount: null,
      selectedMarketArray: [],
      liveOrders: {},
      orderHistory: {},
      revisedOrderLog: [],
      tradeHistory: [],
      redirect: false,
      historyFilteredValues: {},
      tradingAccountsArray: [],
      cpAccounts: [],
      currencyRisk: [],
      ccyFormat: [],
      marketDefArray: [],
      businessDayArray: [],
      lastTrades: [],
      marketPrice: {},
      marketStats: [],
      showQuoteBoard: false,
      OTWindows: {},
      ORWindows: {},
      expandedAccountRows: [],
      OWZindex: [],
    }
    this.websocket = this.props.websocket;
    if(this.websocket === null) {
      this.redirect = <Redirect to = {{
        pathname : "/",
      }}/>
      this.state.redirect = true;
    }
    if(this.props.location.state !== undefined) {
      this.state.tradingAccountsArray = this.props.location.state.tradingAccountsArray;
      this.state.marketDefArray = this.props.location.state.marketDefArray;
      this.state.securityDefArray = this.props.location.state.securityDefArray;
      this.state.cpAccounts = this.props.location.state.cpAccounts;
      this.state.currencyRisk = this.props.location.state.currencyRisk;
      this.state.businessDayArray = this.props.location.state.businessDayArray;
      this.state.ccyFormat = this.props.location.state.ccyFormat;
      this.username = this.props.location.state.username;
      this.cpAccountsCache = this.state.cpAccounts.concat([]);
      this.currencyRiskCache = this.state.currencyRisk.concat([]);
    }
    this.logOut = this.logOut.bind(this);
    this.onCloseOrderModal = this.onCloseOrderModal.bind(this);
    this.onOpenOrderModal = this.onOpenOrderModal.bind(this);
    this.onConfirmOrderModal = this.onConfirmOrderModal.bind(this);
    this.onConfirmReviseModal = this.onConfirmReviseModal.bind(this);
    this.onCloseReviseModal = this.onCloseReviseModal.bind(this);
    this.onOpenReviseModal = this.onOpenReviseModal.bind(this);
    this.handleLogOutMessage = this.handleLogOutMessage.bind(this);
    this.handleOrderMessage = this.handleOrderMessage.bind(this);
    this.handleAccountSelectMessage = this.handleAccountSelectMessage.bind(this);
    this.handleCancelOrderMessage = this.handleCancelOrderMessage.bind(this);
    this.handleReviseOrderMessage = this.handleReviseOrderMessage.bind(this);
    this.handleAccountUpdateMessage = this.handleAccountUpdateMessage.bind(this);
    this.handleGridResizeEnd = this.handleGridResizeEnd.bind(this);
    this.handleMarketChangeMessage = this.handleMarketChangeMessage.bind(this);
    this.handleNewMarketMessage = this.handleNewMarketMessage.bind(this);
    this.handleBusinessDayMessage = this.handleBusinessDayMessage.bind(this);
    this.selectMarketCurrency = this.selectMarketCurrency.bind(this);
    this.quickOrderSubmit = this.quickOrderSubmit.bind(this);
    this.cancelOrder = this.cancelOrder.bind(this);
  }
  componentDidMount() {
    if(this.websocket !== null) {
      this.websocket.addEventListener("message",this.handleOrderMessage);
      this.websocket.addEventListener("message",this.handleNewMarketMessage);
      this.websocket.addEventListener("message",this.handleBusinessDayMessage);
      this.websocket.addEventListener("message",this.handleRejectMessage);
      this.websocket.addEventListener("message",this.handleAccountUpdateMessage);
      this.websocket.onclose = () => {
        window.location.reload();
      }
      this.websocket.onmessage = (evt) => {
        let result = JSON.parse(evt.data);
        if(result.msgType === 17)
          return;
        let msg = this.messagesIn.concat([]);
        if(msg.length === 50)
          msg.shift();
        msg.push(result);
        this.messagesIn = msg;
      };
      // setTimeout(this.updateCachedData,250);
    }
    if(this.props.location.state === undefined){
      return;
    }
    let showModal = this.props.location.state.fromLogin;
    if(showModal) {
      this.onOpenModal();
    }
    let selectedMarketArray = [];
    let owZindex = [];
    for(let i=0;i<this.orderWidgetNumber;i++) {
      selectedMarketArray.push(this.state.marketDefArray[i]);
      owZindex.push(false);
    }
    this.setState({ selectedMarketArray: selectedMarketArray });
    this.setState({ OWZindex: owZindex });
    this.setBodyBg();
  }
  componentDidUpdate(){
    this.setBodyBg();
  }
  messagesIn = [];
  messagesOut = [];
  accUpdateID = 0;
  orderTag = 0;
  mktPrxID = 0;
  otwID = 0;
  orwID = 0;
  sentOrders = {};
  revisedOrders = {};
  cpAccountsCache = [];
  currencyRiskCache = [];
  marketStatsCache = [];
  eventListenerArray = {
    handleLogOut: false,
    handleOrder: false,
    handleAccountSelect: false,
    handleCancelOrder: false,
    handleReviseOrder: false,
    handleAccountUpdate: false,
    handleMarketChange: false,
  };
  previousSelectedAccountId = null;
  selectedAccountId = null;
  marketDefArray = [];
  breakpoints = {
    lg:1200,md:900,sm:468,xs:380,
  }
  cols = {
    lg:12,md:9,sm:6,xs:4,
  }
  counterPartyExtraHeads = ["Cp BIC id","Cp BIC name","Limit","∞","Limit","∞","Enabled","NOP","GOP"];
  counterPartyHeadsBIC = ["Cp BIC Id","CP BIC Name","NOP","GOP","Util"];
  modalTableHeads = [{val:"",span:1},{val:"Id",span:2},{val:"Name",span:3},{val:"",span:1}];
  orderWidgetNumber = 4;

  setBodyBg = () => {
    let clr = theme(this.props.theme,'body');
    document.body.style = `background-color: ${clr} !important;`;
  }

  updateCachedData = () => {
    this.setState({cpAccounts: this.cpAccountsCache});
    this.setState({currencyRisk: this.currencyRiskCache});
    this.setState({marketStats: this.marketStatsCache});
    setTimeout(this.updateCachedData,250);
  }

  updateOutLog = (result) => {
    if(this.messagesOut.length === 50)
      this.messagesOut.shift();
    this.messagesOut.push(result);
  }

  bringWidgetToFront = (index) => {
    let owZindex = this.state.OWZindex.concat([]);
    for(let i=0;i<owZindex.length;i++){
      if(i === index)
        owZindex[i] = true;
      else
        owZindex[i] = false;
    }
    this.setState({OWZindex: owZindex});
  }

  toggleQuoteBoard = () => {
    let layouts = {...this.state.layouts};
    let quote = layouts.lg.find(el => el.i === "quote");
    if(!quote){
      layouts.lg = LayoutService.positionOrderWidgetsQuote([...layouts.lg]);
      layouts.lg.push({i: "quote", x: 0, y: 0, w: 6, h: 16, isResizable: false});
    }
    else{
      layouts.lg = [...LayoutService.positionOrderWidgetsDefault([...layouts.lg])];
      layouts.lg = layouts.lg.filter(el => el.i !== "quote");
    }
    this.setState({layouts});
  }

  toggleOrderBook = () => {
    let layouts = {... this.state.layouts};
    let book = layouts.lg.find(el => el.i === "book");
    if(!book){
      let y = LayoutService.maxY(layouts.lg);
      layouts.lg = [...layouts.lg,{i: "book", x: 0, y: y+1, w: 12, h: 12, minH: 8}];
    }
    else{
      layouts.lg = layouts.lg.filter(el => el.i !== "book");
    }
    this.setState({layouts});
  }

  toggleLive = () => {
    let layouts = {... this.state.layouts};
    let live = layouts.lg.find(el => el.i === "live");
    if(!live){
      let y = LayoutService.maxY(layouts.lg);
      layouts.lg = [...layouts.lg,{i: "live", x: 0, y: y+1, w: 12, h: 8, minH: 8}];
    }
    else{
      layouts.lg = layouts.lg.filter(el => el.i !== "live");
    }
    this.setState({layouts});
  }

  onLayoutChange = (layout, layouts) => {
    LayoutService.saveLayout(layouts,this.props.currentUser);
    this.setState({layouts: layouts});
  }

  selectAccount(selectedAccount) {
    this.setState({selectedAccount: selectedAccount});
    this.selectedAccountId = selectedAccount.accountID;
  }

  changeOrderHistoryTab(tabNumber) {
    this.setState({orderTab: tabNumber});
  }

  selectMarketCurrency(mkt,index) {
    let marketDefArray = this.state.selectedMarketArray.concat([]);
    marketDefArray[index] = mkt;
    this.setState({ selectedMarketArray: marketDefArray });
  }

  onCloseReviseModal(id) {
    let orw = this.state.ORWindows;
    orw[id] = null;
    this.setState({ORWindows: orw});
  }

  onCloseOrderModal(id) {
    let otw = this.state.OTWindows;
    // delete otw[id];
    otw[id] = null;
    this.setState({OTWindows: otw});
  }

  onCloseModal = () => {
    this.setState({ modal: false });
    this.setState(
      { selectedAccount: this.state.previousSelectedAccount },
      () => {this.setSelectAccountBlink()}
    );
    this.selectedAccountId = this.previousSelectedAccountId;
  };

  onCloseSettings = () => {
    this.setState({settings: false});
  }

  onConfirmReviseModal(reviseOrder) {
    console.log("Revising order");
    let msg = {
      "msgType": 43,
      "tag": 12345,
      "orderID": reviseOrder.orderID,
      "price": reviseOrder.price,
      "stopPx": reviseOrder.stopPx,
      "qty": reviseOrder.qty,
      "extent": 5,
    }
    this.websocket.send(JSON.stringify(msg));
    this.updateOutLog(msg);
    this.revisedOrders[reviseOrder.orderID] = {
      "price": reviseOrder.price,
      "qty": reviseOrder.qty,
      "stopPx": reviseOrder.stopPx,
    };
    if(!this.eventListenerArray.handleReviseOrder) {
      this.websocket.addEventListener("message",this.handleReviseOrderMessage);
      this.eventListenerArray.handleReviseOrder = true;
    }
  }

  onConfirmOrderModal(currentMarket,ot) {
    console.log("Sending order");
    this.orderTag = this.orderTag + 1;
    let msg = {
      "msgType": 27,
      "tag": this.orderTag,
      "price": ot.price,
      "stopPx": ot.stopPx,
      "side": ot.side.toString(),
      "qty": ot.qty,
      "type": ot.type.toString(),
      "timeInForce": ot.tif.toString(),
      "maxShow": ot.maxShow,
      "minQty": ot.minQty,
      "expireDate": Number(ot.expireDate),
      "securityID": currentMarket.securityID,
    }
    this.websocket.send(JSON.stringify(msg));
    this.updateOutLog(msg);
    this.sentOrders[this.orderTag] = {
      "product": currentMarket.currencyBase + "/" + currentMarket.currencyTerm,
      "side": ot.side,
      "type": ot.type,
      "qty": ot.qty,
      "price": ot.price,
      "stopPx": ot.stopPx,
      "timeInForce": ot.tif,
      "maxShow": ot.maxShow,
      "minQty": ot.minQty,
      "expireDate": ot.expireDate,
      "account": (this.state.selectedAccount.accountID+" ("+this.state.selectedAccount.name+")"),
      "qtyMinIncrement": currentMarket.qtyMinIncrement,
      "priceMinIncrement": currentMarket.priceMinIncrement,
      "qtyMinClipSize": currentMarket.qtyMinClipSize,
      "decimals": currentMarket.decimals,
      "decimalsPip": currentMarket.decimalsPip,
    };
  }

  quickOrderSubmit(newOrder,market) {
    console.log("Sending quick order");
    this.orderTag = this.orderTag + 1;
    let msg = {
      "msgType": 27,
      "tag": this.orderTag,
      "price": newOrder.price,
      "side": newOrder.side.toString(),
      "qty": newOrder.qty,
      "type": newOrder.type.toString(),
      "timeInForce": newOrder.timeInForce.toString(),
      "securityID": market.securityID,
    }
    this.websocket.send(JSON.stringify(msg));
    this.updateOutLog(msg)
    this.sentOrders[this.orderTag] = {
      "product": market.currencyBase + "/" + market.currencyTerm,
      "side": newOrder.side,
      "type": newOrder.type,
      "qty": newOrder.qty,
      "price": newOrder.price,
      "timeInForce": newOrder.timeInForce,
      "account": (this.state.selectedAccount.accountID+" ("+this.state.selectedAccount.name+")"),
      "qtyMinIncrement": market.qtyMinIncrement,
      "priceMinIncrement": market.priceMinIncrement,
      "qtyMinClipSize": market.qtyMinClipSize,
      "decimals": market.decimals,
      "decimalsPip": market.decimalsPip,
    };
  }

  onConfirmModal() {
    this.setState({ modal: false });
    this.setState(
      { previousSelectedAccount: this.state.selectedAccount},
      () => {this.setSelectAccountBlink()}
    );
    this.previousSelectedAccountId = this.selectedAccountId;
    if(this.selectedAccountId !== null) {
      let msg = {
        "msgType": 320,
        "tag": 12345,
        "accountID": this.selectedAccountId,
      }
      this.websocket.send(JSON.stringify(msg));
      this.updateOutLog(msg);
    }
    if(!this.eventListenerArray.handleAccountSelect) {
      this.websocket.addEventListener("message",this.handleAccountSelectMessage);
      this.eventListenerArray.handleAccountSelect = true;
    }
  }

  onOpenReviseModal(key,event) {
    let coords = {
      x: event.clientX,
      y: event.clientY,
    }
    let order = this.state.liveOrders[key];
    let orw = this.state.ORWindows;
    orw[this.orwID] = {
      "id": this.orwID,
      "order": order,
      "coords": coords,
      "key": key,
    }
    this.setState({ORWindows: orw});
    this.orwID = this.orwID + 1;
  }

  onOpenOrderModal(mktSecurityID,side,event) {
    let coords = {
      x: event.clientX,
      y: event.clientY,
    }
    let thisMarket = this.state.marketDefArray.find(mkt => mkt.securityID === mktSecurityID);
    let marketPrice = this.state.marketPrice[mktSecurityID];
    let otw = this.state.OTWindows;
    otw[this.otwID] = {
      "id": this.otwID,
      "mkt": thisMarket,
      "side": side,
      "mktPrx": marketPrice,
      "coords": coords,
    }
    this.setState({OTWindows: otw});
    this.otwID = this.otwID + 1;
  }

  onOpenModal =() => {
    this.setState({ modal: true });
  };

  onOpenSettings = () => {
    this.setState({settings: true});
  }

  setSelectAccountBlink() {
    if(this.state.selectedAccount === null)
      this.setState({ blinkSelectAccount: true });
    else
      this.setState({ blinkSelectAccount: false });
  }

  handleGridResizeEnd(layout, oldLayoutItem, layoutItem, placeholder,e,element) {
    /* Live Orders panel index is always same as number of order widgets */
  }

  dummyError = () => {
    this.websocket.send(JSON.stringify({
      "msgType": 1000,
      "tag": 1,
    }));
  }

  setupFilterData(property,evt) {
    let value = evt.target.value;
    let historyFilteredValues = this.state.historyFilteredValues;
    if(value == "") {
      delete historyFilteredValues["status"];
    }
    else{
      historyFilteredValues["status"] = value;
    }
    this.setState({historyFilteredValues: historyFilteredValues});
  }

  filterHistory(order) {
    let hFV = this.state.historyFilteredValues;
    let succ = true;
    Object.keys(hFV).map((key,index) => {
      if(!order[key].startsWith(hFV[key])) {
        succ = false;
      }
    });
    return succ;
  }

  findCpNameById(cpID,accID=null) {
    let acc = this.state.tradingAccountsArray.find(el => el.accountID === accID);
    let cp = acc.entries.find(el => el.cpAccountID === cpID);
    return (cp === undefined) ? "N/A" : cp.cpAccountName;
  }

  logOut() {
    let msg = {
      "msgType" : 21,
      "tag" : 1
    }
    const yesFunction = () => {
      this.websocket.send(JSON.stringify(msg));
      this.updateOutLog(msg);
      if(!this.eventListenerArray.handleLogOut) {
        this.websocket.addEventListener("message",this.handleLogOutMessage);
        this.eventListenerArray.handleLogOut = true;
      }
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.logoutConfirmation(yesFunction,toggle,this.props.confirmation.logout);
  }

  cancelAllOrders() {
    let liveOrders = this.state.liveOrders;
    const yesFunction = () => {
      Object.keys(liveOrders).map((key,index) => {
        this.cancelOrder(key,true);
      });
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.pullAllConfirmation(yesFunction,toggle,this.props.confirmation.pullAll);
  }

  cancelOrder(key, skip = false) {
    console.log("Canceling order");
    let lo = Object.assign({},this.state.liveOrders);
    let msg = {
      "msgType": 40,
      "tag": 12345,
      "orderID": key,
    }
    const yesFunction = () => {
      this.websocket.send(JSON.stringify(msg));
      this.updateOutLog(msg);
      if(!this.eventListenerArray.handleCancelOrder) {
        this.websocket.addEventListener("message",this.handleCancelOrderMessage);
        this.eventListenerArray.handleCancelOrder = true;
      }
    }
    const toggle = () => {
      this.props.ToggleConfirmationModal();
    }
    ConfirmationService.pullConfirmation(yesFunction,toggle,lo[key],skip,this.props.confirmation.pull);
  }

  expandAccountRow = (accountID) => {
    let expandedAccountRows = this.state.expandedAccountRows;
    let accRow = expandedAccountRows.find(el => el === accountID);
    if(accRow === undefined){
      expandedAccountRows.push(accountID);
    }
    else{
      expandedAccountRows = expandedAccountRows.filter(el => el !== accountID);
    }
    this.setState({expandedAccountRows: expandedAccountRows});
  }

  removeCanceledOrder(key) {
    let liveOrders = this.state.liveOrders;
    delete liveOrders[key];
    this.setState({ liveOrders: liveOrders });
  }

  removeTradedOrder(oldKey,newKey) {
    let orderHistory = this.state.orderHistory;
    orderHistory[oldKey].status = "Finished";
    this.setState({orderHistory: orderHistory});
    this.removeCanceledOrder(oldKey);
  }

  modifyRevisedOrder(key,newOrderID) {
    let orderHistory = this.state.orderHistory;
    let liveOrders = this.state.liveOrders;
    liveOrders[key].remQty = this.revisedOrders[key].qty;
    liveOrders[key].price = this.revisedOrders[key].price;
    liveOrders[key].stopPx = this.revisedOrders[key].stopPx;
    if(newOrderID !== null){
      let order = liveOrders[key];
      delete liveOrders[key];
      liveOrders[newOrderID] = order;
      order = orderHistory[key];
      delete orderHistory[key];
      orderHistory[newOrderID] = order;
      this.setState({orderHistory: orderHistory});
    }
    this.setState({ liveOrders: liveOrders});
  }

  addCanceledToHistory(key,status) {
    let orderHistory = this.state.orderHistory;
    orderHistory[key].status = status;
    orderHistory[key].remQty = 0;
    this.setState({orderHistory: orderHistory});
  }

  addRejectedToHistory(order,status) {
    let orderHistory = this.state.orderHistory;
    if(orderHistory.hasOwnProperty(order.orderID)) {
      orderHistory[order.orderID].status = status;
      orderHistory[order.orderID].rejection = (order.errorCode !== undefined) ? order.errorCode : order.status;
      orderHistory[order.orderID].remQty = 0;
      this.setState({orderHistory: orderHistory});
    }
    else{
      let receivedOrder = this.sentOrders[order.tag];
      receivedOrder.status = status;
      receivedOrder.rejection = (order.errorCode !== undefined) ? order.errorCode : order.status;
      receivedOrder.remQty = 0;
      orderHistory[order.tag] = receivedOrder;
      this.setState({orderHistory: orderHistory});
    }
  }

  addExpiredToHistory(order,status) {
    this.addRejectedToHistory(order,status);
  }

  addTradedToTrades(trade,trdCache,liveCache,histCache) {
    let orderID = (trade.secOrderID != null) ? trade.secOrderID : trade.orderID;
    liveCache[orderID].remQty = trade.leavesQty;
    liveCache[orderID].cumQty = trade.cumQty;
    if(orderID !== trade.orderID){
      let order = liveCache[orderID];
      delete liveCache[orderID];
      liveCache[trade.orderID] = order;
      delete histCache[orderID];
      histCache[trade.orderID] = order;
      orderID = trade.orderID;
    }
    trdCache.push({
      "side": liveCache[orderID].side,
      "product": liveCache[orderID].product,
      "orderID": trade.orderID,
      "secOrderID": trade.secOrderID,
      "tradeID": trade.tradeID,
      "execID": trade.execID,
      "transactTime": trade.transactTime,
      "lastPx": trade.lastPx,
      "lastQty": trade.lastQty,
      "cumQty": trade.cumQty,
      "leavesQty": trade.leavesQty,
      "capacity": trade.capacity,
      "account": liveCache[orderID].account,
      "decimals": liveCache[orderID].decimals,
      "decimalsPip": liveCache[orderID].decimalsPip,
    })
  }

  addLiveToHistory(order,orderID,status) {
    let orderHistory = this.state.orderHistory;
    if(order.status !== undefined)
      order.status = (order.status === "0") ? status : "Part. Filled";
    else
      order.status = status;
    orderHistory[orderID] = order;
    this.setState({orderHistory: orderHistory});
  }

  addRevisedToLog(order,status) {
    let liveOrders = this.state.liveOrders;
    let revisedOrderLog = this.state.revisedOrderLog;
    let orderID = order.orderID;
    if(order.secOrderID != null){
      orderID = order.secOrderID;
    }
    revisedOrderLog.push({
      "orderID": order.orderID,
      "secOrderID": order.secOrderID,
      "origPrice": liveOrders[orderID].price,
      "newPrice": this.revisedOrders[orderID].price,
      "origQty": liveOrders[orderID].qty,
      "newQty": this.revisedOrders[orderID].qty,
      "origExp": null,
      "newExp": null,
      "origStopPx": null,
      "newStopPx": null,
      "extent": null,
      "status": status,
      "decimals": liveOrders[orderID].decimals,
      "decimalsPip": liveOrders[orderID].decimalsPip,
    });
  }

  handleLogOutMessage(evt) {
    let result = JSON.parse(evt.data);
    console.log(result, "Logout result");
    if(result.msgType === 20) {
      this.redirect = <Redirect to = {{
        pathname : "/",
      }}/>
      this.setState({redirect: true});
    }
    if(result.msgType === 66) {

    }
  }

  handleRejectMessage = (evt) => {
    let result = JSON.parse(evt.data);
    if(result.msgType === 66) {
      console.log("Rejected message");
      let errorLog = JSON.stringify({
        out: this.messagesOut,
        in: this.messagesIn,
      },null,2);
      let d = new Date();
      let day = d.getDate();
      let m = d.getMonth() + 1;
      let hour = d.getHours();
      let minute = d.getMinutes();
      let second = d.getSeconds();
      m = m.toString();
      let year = d.getFullYear().toString();
      day = (day < 10) ? "0"+day : day;
      m = (m < 10) ? "0"+m : m;
      hour = (hour < 10) ? "0"+hour : hour;
      minute = (minute < 10) ? "0"+minute : minute;
      second = (second < 10) ? "0"+second : second;
      let date = year + m + day + "_" + hour + minute + second;
      fileDownload(errorLog, 'purecryptoLogReport_' + date + '.txt');
    }
  }

  handleBusinessDayMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 325) {
      console.log("Business day updated");
      let businessDayArray = this.state.businessDayArray.concat([]);
      let updateIndex = businessDayArray.findIndex(el => el.securityID === result.securityID);
      if(updateIndex === -1)
        businessDayArray.push(result);
      else
        businessDayArray[updateIndex] = result;
      this.setState({businessDayArray: businessDayArray});
    }
  }

  handleOrderMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 28) {
      //// TODO: Clear received orders from sent orders
      console.log("Order inserted");
      let receivedOrder = this.sentOrders[result.tag];
      receivedOrder.transactTime = result.transactTime;
      let liveOrders = this.state.liveOrders;
      liveOrders[result.orderID] = receivedOrder;
      this.setState({ liveOrders: liveOrders });
      let status = (receivedOrder.stopPx === undefined || receivedOrder.stopPx === 0) ? "Live" : "Waiting";
      this.addLiveToHistory(receivedOrder,result.orderID,status);
    }
    if(result.msgType === 29) {
      console.log("Order rejected");
      this.addRejectedToHistory(result,"Rejected");
      this.removeCanceledOrder(result.orderID);
    }
    if(result.msgType === 30) {
      console.log("Order expired");
      this.addExpiredToHistory(result,"Expired");
      this.removeCanceledOrder(result.orderID);
    }
    if(result.msgType === 31) {
      console.log("Stop Order Activated");
      let liveOrders = Object.assign({},this.state.liveOrders);
      liveOrders[result.orderID].status = "Live";
      this.setState({liveOrders: liveOrders});
    }
    if(result.msgType === 37) {
      console.log("Order completed");
      let trdCache = this.state.tradeHistory.concat([]);
      let liveCache = this.state.liveOrders;
      let histCache = this.state.orderHistory;
      result.entries.forEach(trd => {
        this.addTradedToTrades(trd,trdCache,liveCache,histCache);
        if(trd.leavesQty === 0) {
          if(this.props.alert.lmt)
            sound.play()
          if(trd.secOrderID != null) {
            this.removeTradedOrder(trd.secOrderID,trd.orderID);
          }
          else{
            this.removeTradedOrder(trd.orderID,null);
          }
        }
      })
      if(this.props.alert.partial)
        sound.play();
      this.setState({tradeHistory: trdCache});
      this.setState({liveOrders: liveCache});
      this.setState({orderHistory: histCache});
    }
    if(result.msgType === 47) {
      console.log("GTC received");
      let marketDefArray = this.state.marketDefArray;
      let thisMarket = (marketDefArray.filter(mkt => mkt.securityID === result.securityID))[0];
      let liveOrders = this.state.liveOrders;
      let account = (this.state.tradingAccountsArray.filter(acc => acc.accountID === result.account))[0];
      let status = (result.isStopActivated === false) ? "Waiting" : "Live";
      result.remQty = result.leavesQty;
      result.account = result.account + " (" + account.name + ")";
      result.product = thisMarket.currencyBase + "/" + thisMarket.currencyTerm;
      result.qtyMinClipSize = thisMarket.qtyMinClipSize;
      result.qtyMinIncrement = thisMarket.qtyMinIncrement;
      result.priceMinIncrement = thisMarket.priceMinIncrement;
      result.decimals = thisMarket.decimals;
      result.decimalsPip = thisMarket.decimalsPip;
      liveOrders[result.orderID] = result;
      this.setState({ liveOrders: liveOrders});
      this.addLiveToHistory(result,result.orderID,status);
    }
  }

  handleAccountSelectMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 321) {
      console.log("Account selected");
      this.setState({isAccountSelected: true});
      this.accUpdateID = this.accUpdateID + 1;
      if(!this.eventListenerArray.handleMarketChange) {
        this.websocket.addEventListener("message",this.handleMarketChangeMessage);
        this.eventListenerArray.handleMarketChange = true;
      }
    }
  }

  handleAccountUpdateMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 300) {
      console.log("Updating trading accounts");
      let updatedArray = AccountService.updateTradingAccountsArray(result,this.state.tradingAccountsArray);
      this.setState({tradingAccountsArray: updatedArray});
    }
    if(result.msgType === 305) {
      console.log("Updating counterparty");
      let updatedArray = this.state.cpAccounts.concat([]);
      result.entries.forEach(el => {
        updatedArray = AccountService.updateCpAccounts(el,updatedArray);
      });
      this.setState({cpAccounts: updatedArray});
    }
    if(result.msgType === 310) {
      console.log("Updating currency risk");
      let updatedArray = this.state.currencyRisk.concat([]);
      result.entries.forEach(el => {
        updatedArray = AccountService.updateCurrencyRisk(el,updatedArray);
      });
      this.setState({currencyRisk: updatedArray});
    }
  }

  handleCancelOrderMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 41) {
      console.log("Order canceled successfully");
      this.addCanceledToHistory(result.orderID,"Canceled");
      this.removeCanceledOrder(result.orderID);
    }
    if(result.msgType === 42) {
      console.log("Order cancel rejected");
    }
  }

  handleReviseOrderMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 44) {
      console.log("Order revised");
      this.addRevisedToLog(result,"OK");
      if(result.secOrderID != null) {
        this.modifyRevisedOrder(result.secOrderID,result.orderID);
      }
      else{
        this.modifyRevisedOrder(result.orderID,null);
      }
    }
    if(result.msgType === 45) {
      console.log("Order revise rejected");
      this.addRevisedToLog(result,result.errorCode);
    }
  }

  handleNewMarketMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 261) {
      let marketDefArray = this.state.marketDefArray.concat([]);
      marketDefArray = marketDefArray.concat([result]);
      this.setState({marketDefArray: marketDefArray});
    }
    if(result.msgType === 268) {
      let securityDefArray = this.state.securityDefArray.concat([]);
      let secIndex = securityDefArray.findIndex(sct => sct.securityID === result.securityID);
      if(secIndex === -1)
        securityDefArray.push(result);
      else
        securityDefArray[secIndex].status = result.status;
      this.setState({securityDefArray: securityDefArray});
    }
  }

  handleMarketChangeMessage(evt) {
    let result = JSON.parse(evt.data);
    if(result.msgType === 265) {
      let marketPrice = Object.assign({},this.state.marketPrice);
      if(marketPrice[result.securityID] === undefined){
        marketPrice[result.securityID] = {};
      }
      let entries = {
        "buy": result.entries.filter(ent => ent.mdEntryType === "0"),
        "sell": result.entries.filter(ent => ent.mdEntryType === "1"),
      }
      marketPrice[result.securityID][result.type] = entries;
      marketPrice[result.securityID][result.type]["buy"].sort(function(a,b){return b.mdEntryPx - a.mdEntryPx});
      marketPrice[result.securityID][result.type]["sell"].sort(function(a,b){return a.mdEntryPx - b.mdEntryPx});
      this.setState({marketPrice: marketPrice});
    }
    if(result.msgType === 266) {
      const marketPrice = produce(this.state.marketPrice, draft => {
        result.entries.forEach(ent => {
          let side = (ent.mdEntryType === "0") ? "buy" : "sell";
          let priceArray = draft[ent.securityID][result.type][side];
          switch(ent.mdUpdateAction) {
            case "0":
              priceArray.push(ent);
              if(side === "buy")
                priceArray.sort(function(a,b){return b.mdEntryPx - a.mdEntryPx});
              else
                priceArray.sort(function(a,b){return a.mdEntryPx - b.mdEntryPx});
              break;
            case "1":
              let priceIndex = priceArray.findIndex(prc => prc.mdEntryPx === ent.mdEntryPx);
              priceArray[priceIndex].mdEntrySize = ent.mdEntrySize;
              break;
            case "2":
              priceIndex = priceArray.findIndex(prc => prc.mdEntryPx === ent.mdEntryPx);
              priceArray.splice(priceIndex,1);
              break;
            case "3":
              priceArray.length = 0;
              break;
          }
        });
      });
      this.mktPrxID = this.mktPrxID + 1;
      this.setState({marketPrice: marketPrice});
    }
    if(result.msgType === 267) {
      let stats = this.state.marketStats.concat([]);
      result.entries.forEach(stat => {
        let index = stats.findIndex(el => el.securityID === stat.securityID);
        if(index === -1)
          stats.push(stat);
        else
          stats[index] = stat;
      });
      this.setState({marketStats: stats});
    }
    if(result.msgType === 269) {
      const lastTrades = produce(this.state.lastTrades, draft => {
        draft.unshift(...result.entries.reverse());
      });
      this.setState({lastTrades: lastTrades});
    }
  }

  render() {
    if(this.state.redirect)
      return this.redirect;
    else
      return (
        <div>
          <Bootstrap/>
          <FontAwesome/>
          {Object.values(this.state.OTWindows).map(val => {
            return(
              <OrderTicket
                close={this.onCloseOrderModal}
                data={val}
                isAccountSelected={this.state.isAccountSelected}
                submit={this.onConfirmOrderModal}
              />
            )
          })}
          {Object.values(this.state.ORWindows).map(val => {
            return(
              <OrderRevise
                close={this.onCloseReviseModal}
                data={val}
                submit={this.onConfirmReviseModal}
              />
            )
          })}
          <TopNavbar
            username={this.username}
            logOut={this.logOut}
            onOpenModal={this.onOpenModal}
            dummyError={this.dummyError}
            blink={this.state.blinkSelectAccount}
            onOpenSettings={this.onOpenSettings}
            selectedAccount={this.state.isAccountSelected === true ? this.state.selectedAccount : null}/>
          <ConfirmationModal/>
          <Modal
            open={this.state.modal}
            onClose={this.onCloseModal}
            center
            closeOnEsc={false}
            showCloseIcon={false}
            animationDuration={200}
            styles={{
              modal:{
                padding: 0,
                maxWidth:950,
                width:950,
              }
            }}
          >
            {this.renderModal()}
          </Modal>
          <Modal
            open={this.state.settings}
            onClose={this.onCloseSettings}
            center
            closeOnEsc={false}
            showCloseIcon={false}
            animationDuration={200}
            styles={{
              modal:{
                padding: 0,
                maxHeight:300,
                height:300,
                maxWidth:600,
                width:600,
              }
            }}
          >
            {this.renderSettings()}
          </Modal>
          <div className="d-flex flex-row">
            <div className="ml-1 mt-1">
              <Button variant="dark" className="customBgBlue quoteButton" size="sm" onClick={this.toggleQuoteBoard}>
                QB. <i class={(this.state.layouts.lg.find(el => el.i === "quote") ? "fas fa-check-square" : "far fa-square")}></i></Button>
            </div>
            <div className="ml-1 mt-1">
              <Button variant="dark" className="customBgBlue quoteButton" size="sm" onClick={this.toggleOrderBook}>
                O. Book <i class={(this.state.layouts.lg.find(el => el.i === "book") ? "fas fa-check-square" : "far fa-square")}></i></Button>
            </div>
            <div className="ml-1 mt-1">
              <Button variant="dark" className="customBgBlue quoteButton" size="sm" onClick={this.toggleLive}>
                Live <i class={(this.state.layouts.lg.find(el => el.i === "live") ? "fas fa-check-square" : "far fa-square")}></i></Button>
            </div>
            <div className="ml-1 mt-1">
              <Button variant="danger" className="quoteButton" size="sm" onClick={() => {this.cancelAllOrders()}}>
                PULL ALL</Button>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <ResponsiveGridLayout
              autoSize={false}
              className="layout"
              rowHeight={18}
              breakpoints={this.breakpoints}
              cols={this.cols}
              draggableCancel='.nonDraggable'
              onResizeStop={this.handleGridResizeEnd}
              layouts={this.state.layouts}
              onLayoutChange={(layout, layouts) =>
                this.onLayoutChange(layout, layouts)
              }
            >
              {this.renderResponsiveGridContent()}
            </ResponsiveGridLayout>
          </div>
        </div>
      );
  }

  renderResponsiveGridContent() {
    let gridContent = [];
    gridContent.push(this.renderQuoteBoard());
    gridContent.push(this.renderOrderWidgets(this.orderWidgetNumber));
    gridContent.push(this.renderLiveOrders());
    gridContent.push(this.renderOrderHistory());
    return gridContent.filter(el => el !== false);
  }

  renderModal() {
    if(!this.state.modal)
      return null;
    return (
      <div className="modalPadding"
        style={{backgroundColor:theme(this.props.theme,"bg"),color:theme(this.props.theme,"text")}}>
        <span className="d-flex flex-row justify-content-between">
          <h3>Choose BIC</h3>
          <h3 onClick={() => this.onCloseModal()} className="closeBtn" style={{}}>
            <i class="fa fa-window-close" aria-hidden="true"></i>
          </h3>
        </span>
        <table className="customTable mb-2">
          <thead>
            <tr>
              {this.modalTableHeads.map(thead => {
                return <th colSpan={thead.span} style={{backgroundColor:theme(this.props.theme,"bg")}}>{thead.val}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.tradingAccountsArray.map(acc=>{
              let rows = [];
              let rowExpanded = this.state.expandedAccountRows.find(el => el === acc.accountID);
              rows.push(
                <tr style={{fontWeight:'bold'}}>
                  <td colSpan={1} className="text-center" onClick={() => this.expandAccountRow(acc.accountID)}
                    style={{width:'5ch'}}
                  >
                    <i className={"fas fa-" + (rowExpanded !== undefined ? "minus" : "plus")}></i>
                  </td>
                  <td colSpan={2} style={{color:theme(this.props.theme,'textSec')}}>{acc.accountID}</td>
                  <td colSpan={3} style={{color:theme(this.props.theme,'textSec')}}>{acc.name}</td>
                  <td colSpan={1} className="text-center" onClick={() => {this.selectAccount(acc)}}
                    style={{width:'5ch'}}
                  >
                    <i className={(this.selectedAccountId === acc.accountID ? "fas" : "far") + " fa-circle"}></i>
                  </td>
                </tr>
              );
              let cpHead = (<tr>
                <th colSpan={1} style={{width:'5ch',backgroundColor:theme(this.props.theme,"bg")}}></th>
                {this.counterPartyHeadsBIC.map(thead => {
                  return <th style={{backgroundColor:theme(this.props.theme,"bg"), color:theme(this.props.theme,'textPri')}}>{thead}</th>
                })}
                <th colSpan={1} style={{width:'5ch',backgroundColor:theme(this.props.theme,"bg")}}></th>
              </tr>)
              let cp = this.state.cpAccounts.find(cp => cp.accountID === acc.accountID);
              let ccy = this.state.ccyFormat.find(el => el.currency === acc.currencyHome);
              let ccyFmt = Formatter.ccySymbol(ccy.currency);
              let cps = cp.entries.map(ent => {
                let nopLimit = ent.isInfinity_NOP ? "∞" : ent.NOP;
                let gopLimit = ent.isInfinity_GOP ? "∞" : ent.GOP;
                return(
                  <tr style={{fontWeight:'bold',color:theme(this.props.theme,'textBack')}}>
                    <td></td>
                    <td>{ent.cpAccountID}</td>
                    <td>{this.findCpNameById(ent.cpAccountID,acc.accountID)}</td>
                    <td>{ccyFmt + " " + Formatter.formatPrice(nopLimit,{decimals:ccy.decimals})}</td>
                    <td>{ccyFmt + " " + Formatter.formatPrice(gopLimit,{decimals:ccy.decimals})}</td>
                    <td>
                      {ccyFmt + " " + Formatter.formatPrice(ent.uNOP,{decimals:ccy.decimals})}{" / "}
                      {ccyFmt + " " + Formatter.formatPrice(ent.uGOP,{decimals:ccy.decimals})}</td>
                    <td></td>
                  </tr>
                )
              });
              if(rowExpanded !== undefined){
                rows.push(cpHead);
                rows.push(cps);
              }
              return rows;
            })}
          </tbody>
        </table>
        <div className="d-flex flex-row justify-content-end w-100">
          <div onClick={() => this.onConfirmModal()} className="successButton">
            SELECT
          </div>
        </div>
      </div>
    );
  }

  renderSettings() {
    return(
      <Settings
        close={this.onCloseSettings}
      />
    )
  }

  renderQuoteBoard() {
    let ly = this.state.layouts.lg.concat([]);
    if(ly.find(el => el.i === "quote"))
      return(
        <div className="p-2" key={"quote"}
          style={{border:'1px solid #3a3a3a',backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
          <QuoteBoard
            marketPrice={this.state.marketPrice}
            marketStats={this.state.marketStats}
            marketDefArray={this.state.marketDefArray}
            securityDefArray={this.state.securityDefArray}
            lastTrades={this.state.lastTrades}
            onOpenOrderModal={this.onOpenOrderModal}
          />
        </div>
      )
    else
      return false;
  }

  renderOrderWidgets(number = 1) {
    let orderWidgets = [];
    for(let i=0;i<number;i++) {
      let zIndex = (this.state.OWZindex[i]) ? 20 : 0;
      this.state.selectedMarketArray.length !== 0 ? orderWidgets.push (
        <div className="p-2" key={i}
          style={{zIndex:10+zIndex,borderRadius:4,border:'1px solid #3a3a3a',backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
          <OrderWidget
            marketPrice={this.state.marketPrice}
            selectedMarketArray={this.state.selectedMarketArray}
            currentIndex={i}
            marketDefArray={this.state.marketDefArray}
            selectMarketCurrency={this.selectMarketCurrency}
            businessDayArray={this.state.businessDayArray}
            marketStats={this.state.marketStats}
            onOpenOrderModal={this.onOpenOrderModal}
            quickOrderSubmit={this.quickOrderSubmit}
            bringWidgetToFront={this.bringWidgetToFront}
          />
        </div>
      ) : orderWidgets.push(<div key={i}></div>);
    }
    return orderWidgets;
  }

  renderLiveOrders() {
    let ly = this.state.layouts.lg.concat([]);
    if(ly.find(el => el.i === "live")){
      let realizedPnL = "N/A";
      let ccy = this.state.currencyRisk.find(el => el.accountID === this.selectedAccountId);
      if(ccy !== undefined){
        if(ccy.realizedPnL !== undefined){
          let ccyFmt = this.state.ccyFormat.find(el => el.currency === this.state.selectedAccount.currencyHome);
          realizedPnL = Formatter.ccySymbol(ccyFmt.currency) + " " + Formatter.formatPrice(ccy.realizedPnL,{decimals:ccyFmt.decimals});
        }
      }
      return(
        <div className="pb-2" key={"live"}
          style={{border:'1px solid #3a3a3a',backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
          <div className="mb-2 pl-2 pr-2 pt-2 d-flex flex-row justify-content-between" style={{}}>
            <span className="font-weight-bold">{"Live Orders " + realizedPnL}</span>
            <LiveSettings
              columns={this.props.liveColumns}
              update={this.props.UpdateLiveColumns}
            />
          </div>
          <div style={{height:'100%'}} className="nonDraggable pl-2 pr-2 pb-2">
            <LiveOrders
              liveOrders={Object.assign({},this.state.liveOrders)}
              cancel={this.cancelOrder}
              revise={this.onOpenReviseModal}
            />
          </div>
        </div>
      );
    }
    else
      return false;
  }

  orderBookSettings = () => {
    let settings = {};
    switch(this.state.orderTab){
      case 0:
        settings = {
          columns: this.props.orderHistoryColumns,
          update: this.props.UpdateOrderHistoryColumns,
        }
        break;
      case 1:
        settings = {
          columns: this.props.orderReviseColumns,
          update: this.props.UpdateOrderReviseColumns,
        }
        break;
      case 3:
        settings = {
          columns: this.props.cpColumns,
          update: this.props.UpdateCpColumns,
        }
        break;
      case 4:
        settings = {
          columns: this.props.ccyColumns,
          update: this.props.UpdateCcyColumns,
        }
        break;
      case 5:
        settings = {
          columns: this.props.tradeColumns,
          update: this.props.UpdateTradeColumns,
        }
        break;
    }
    return settings;
  }

  renderOrderHistory() {
    let ly = this.state.layouts.lg.concat([]);
    if(ly.find(el => el.i === "book")){
      let tabMenuClasses = "d-inline mr-2 mb-2 pb-1 font-weight-bold ";
      return(
        <div className="p-2" key={"book"}
          style={{border:'1px solid #3a3a3a',backgroundColor:theme(this.props.theme,'bg'),color:theme(this.props.theme,'text')}}>
          <div className="d-flex flex-row justify-content-between">
            <div>
              <div
                onClick={() => this.changeOrderHistoryTab(0)}
                className={tabMenuClasses + (this.state.orderTab === 0 ? "selectedTab" : "text-secondary")}
              >Order History</div>
              <div
                onClick={() => this.changeOrderHistoryTab(1)}
                className={tabMenuClasses + (this.state.orderTab === 1 ? "selectedTab" : "text-secondary")}
              >Revised Orders</div>
              <div
                onClick={() => this.changeOrderHistoryTab(3)}
                className={tabMenuClasses + (this.state.orderTab === 3 ? "selectedTab" : "text-secondary")}
              >Counterparty Limits</div>
              <div
                onClick={() => this.changeOrderHistoryTab(4)}
                className={tabMenuClasses + (this.state.orderTab === 4 ? "selectedTab" : "text-secondary")}
              >Currency Limits</div>
              <div
                onClick={() => this.changeOrderHistoryTab(5)}
                className={tabMenuClasses + (this.state.orderTab === 5 ? "selectedTab" : "text-secondary")}
              >Trades</div>
            </div>
            <LiveSettings
              columns={this.orderBookSettings().columns}
              update={this.orderBookSettings().update}
            />
          </div>
          <div style={{height:'100%'}} className="nonDraggable mt-2">
            {this.state.orderTab === 0 ?
              <OrderHistory
                orderHistory={Object.assign({},this.state.orderHistory)}
              />
            : null}
            {this.state.orderTab === 1 ?
              <RevisedOrders
                revisedOrders={this.state.revisedOrderLog.concat([])}
              />
            : null}
            {this.state.orderTab === 3 ?
              <CpLimits
                cpLimits={this.state.cpAccounts.find(cp => cp.accountID === this.selectedAccountId)}
                account={this.state.tradingAccountsArray.find(el => el.accountID === this.selectedAccountId)}
                ccyFmt={this.state.ccyFormat.concat([])}
              />
            : null}
            {this.state.orderTab === 4 ?
              <CcyLimits
                currencyRisk={this.state.currencyRisk.find(rsk => rsk.accountID === this.selectedAccountId)}
                ccyFmt={this.state.ccyFormat.concat([])}
              />
            : null}
            {this.state.orderTab === 5 ?
              <Trades
                trades={this.state.tradeHistory.concat([])}
              />
            : null}
          </div>
        </div>
      );
    }
    else
      return false;
  }
}

const mapStateToProps = state => {
  return{
    currentUser: state.currentUser,
    confirmation: state.confirmation,
    alert: state.alert,
    theme: state.theme,
    liveColumns: state.liveColumns,
    orderHistoryColumns: state.orderHistoryColumns,
    orderReviseColumns: state.orderReviseColumns,
    cpColumns: state.cpColumns,
    ccyColumns: state.ccyColumns,
    tradeColumns: state.tradeColumns,
  }
}

export default connect(mapStateToProps,actions)(Home);
