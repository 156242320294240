const initial = {
  pull: true,
  pullAll: true,
  revise: true,
  submit: true,
  logout: true,
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_CONFIRMATION":
      settings[action.payload.username].confirmation = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_CONFIRMATION":
      if(settings[action.payload].confirmation === undefined){
        settings[action.payload].confirmation = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].confirmation;
    default:
      return state;
  }
}
