export const UpdateQuoteAggregation = (data) => {
  return{
    type: "UPDATE_QUOTE_AGGREGATION",
    payload: data,
  }
}

export const InitQuoteAggregation = (data) => {
  return{
    type: "INIT_QUOTE_AGGREGATION",
    payload: data,
  }
}

export const UpdateConfirmation = (data) => {
  return{
    type: "UPDATE_CONFIRMATION",
    payload: data,
  }
}

export const InitConfirmation = (data) => {
  return{
    type: "INIT_CONFIRMATION",
    payload: data,
  }
}

export const UpdateAlert = (data) => {
  return{
    type: "UPDATE_ALERT",
    payload: data,
  }
}

export const InitAlert = (data) => {
  return{
    type: "INIT_ALERT",
    payload: data,
  }
}

export const UpdateTheme = (data) => {
  return{
    type: "UPDATE_THEME",
    payload: data,
  }
}

export const InitTheme = (data) => {
  return{
    type: "INIT_THEME",
    payload: data,
  }
}

export const UpdateLiveColumns = (data) => {
  return{
    type: "UPDATE_LIVE_COLUMNS",
    payload: data,
  }
}

export const InitLiveColumns = (data) => {
  return{
    type: "INIT_LIVE_COLUMNS",
    payload: data,
  }
}

export const UpdateOrderHistoryColumns = (data) => {
  return{
    type: "UPDATE_ORDER_HISTORY_COLUMNS",
    payload: data,
  }
}

export const InitOrderHistoryColumns = (data) => {
  return{
    type: "INIT_ORDER_HISTORY_COLUMNS",
    payload: data,
  }
}

export const UpdateOrderReviseColumns = (data) => {
  return{
    type: "UPDATE_ORDER_REVISE_COLUMNS",
    payload: data,
  }
}

export const InitOrderReviseColumns = (data) => {
  return{
    type: "INIT_ORDER_REVISE_COLUMNS",
    payload: data,
  }
}

export const UpdateCpColumns = (data) => {
  return{
    type: "UPDATE_CP_COLUMNS",
    payload: data,
  }
}

export const InitCpColumns = (data) => {
  return{
    type: "INIT_CP_COLUMNS",
    payload: data,
  }
}

export const UpdateCcyColumns = (data) => {
  return{
    type: "UPDATE_CCY_COLUMNS",
    payload: data,
  }
}

export const InitCcyColumns = (data) => {
  return{
    type: "INIT_CCY_COLUMNS",
    payload: data,
  }
}

export const UpdateTradeColumns = (data) => {
  return{
    type: "UPDATE_TRADE_COLUMNS",
    payload: data,
  }
}

export const InitTradeColumns = (data) => {
  return{
    type: "INIT_TRADE_COLUMNS",
    payload: data,
  }
}

export const SetCurrentUser = (data) => {
  return{
    type: "SET_CURRENT_USER",
    payload: data,
  }
}

export const ToggleConfirmationModal = () => {
  return{
    type: "TOGGLE_CONFIRMATION_MODAL",
    payload: null,
  }
}
