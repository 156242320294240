import React from 'react';
import F from '../../utils/Formatter';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { connect } from 'react-redux';

class CcyLimits extends React.Component {
  constructor(props){
    super(props);
  }

  rowGetter = (index,currencyRisk) => {
    return currencyRisk[index];
  }

  render(){
    let currencyRisk = (this.props.currencyRisk === undefined) ? [] : this.props.currencyRisk.entries;
    return(
      <AutoSizer>
        {({width,height}) => (
          <Table
            headerHeight={20}
            headerClassName="vHead"
            height={height-30}
            noRowsRenderer={() => {return <div></div>}}
            rowHeight={20}
            rowGetter={({index}) => this.rowGetter(index,currencyRisk)}
            rowCount={currencyRisk.length}
            rowClassName="vRow"
            width={width}
          >
            {this.props.c.currency.show ? <Column
              label="Currency"
              className="vCell"
              dataKey="currency"
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.nop.show ? <Column
              label="NOP (Limit)"
              className="vCell"
              dataKey="NOP"
              cellDataGetter={({rowData}) => {
                let ccy = this.props.ccyFmt.find(el => el.currency === rowData.currency)
                return F.ccySymbol(rowData.currency) + " " + F.formatPrice(rowData.NOP,{decimals:ccy.decimals})
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.infinity.show ? <Column
              label="∞"
              className="vCell"
              dataKey="isInfinity"
              cellDataGetter={({rowData}) => {
                if(rowData.isInfinity === undefined)
                  return "";
                return rowData.isInfinity.toString()
              }}
              width={1}
              flexGrow={0.8}
            /> : null}
            {this.props.c.enabled.show ? <Column
              label="Enabled"
              className="vCell"
              dataKey="isEnabled"
              cellDataGetter={({rowData}) => {
                if(rowData.isInfinity === undefined)
                  return "";
                return rowData.isEnabled.toString()
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.unop.show ? <Column
              label="NOP (Util)"
              className="vCell"
              dataKey="uNOP"
              cellDataGetter={({rowData}) => {
                let ccy = this.props.ccyFmt.find(el => el.currency === rowData.currency)
                return F.ccySymbol(rowData.currency) + " " + F.formatPrice(rowData.uNOP,{decimals:ccy.decimals})
              }}
              width={1}
              flexGrow={1}
            /> : null}
            {this.props.c.realizedPnL.show ? <Column
              label="PnL"
              className="vCell"
              dataKey="realizedPnL"
              cellRenderer={({rowData}) => {
                if(rowData.realizedPnL === undefined)
                  return "";
                let ccy = this.props.ccyFmt.find(el => el.currency === rowData.currency)
                return(
                  <span style={{color:(rowData.realizedPnL.toString().indexOf('-') > -1 ? "#e53d3d" : "#179917")}}>
                    {F.ccySymbol(rowData.currency) + " " + F.formatPrice(rowData.realizedPnL,{decimals:ccy.decimals})}
                  </span>
                )
              }}
              width={1}
              flexGrow={1}
            /> : null}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

const mapStateToProps = state => {
  return{
    c: state.ccyColumns,
  }
}

export default connect(mapStateToProps,null)(CcyLimits);
