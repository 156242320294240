import React, {
  Component
} from 'react';
import styles from './Login.css';
import {
  Redirect
} from 'react-router';
import Bootstrap from '../../utils/Bootstrap';
import FontAwesome from '../../utils/FontAwesome';
import { applyVersionChange } from '../../utils/SettingsUpdate';
import AccountService from '../../services/AccountService';
import { connect } from 'react-redux';
import * as actions from '../../actions';

const version = 3;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      msgArray: [],
      loading: false,
      username: null,
      password: null,
    }
    this.checkVersion();
    this.loginHandler = this.loginHandler.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleTimeout = this.handleTimeout.bind(this);
    this.sendCredentials = this.sendCredentials.bind(this);
  }
  msgTimeout = false;
  websocket = null;
  tradingAccountsArray = [];
  securityDefArray = [];
  marketDefArray = [];
  cpAccounts = [];
  currencyRisk = [];
  businessDayArray = [];
  ccyFormat = [];
  env = "wss://tde-alphatestenv.redirectme.net:25008";

  componentDidUpdate() {
    if(this.msgTimeout) {
      this.msgTimeout = false;
      return;
    }
    if(this.props.websocket !== null) {
      this.websocket = this.props.websocket;
      this.websocket.onmessage = this.handleMessage;
      this.websocket.onopen = this.sendCredentials;
      setTimeout(this.handleTimeout,5000);
    }
  }

  handleTimeout(evt) {
    console.log("Socked timeout state is " + this.websocket.readyState);
    if(this.websocket.readyState !== 1) {
      this.websocket.close();
      this.websocket = null;
      this.setState({loading: false});
      this.msgTimeout = true;
      this.setState({message: "Can't reach server"});
    }
  }

  checkVersion() {
    let v = localStorage.getItem("version");
    if(v === null || isNaN(v)){
      localStorage.clear();
      localStorage.setItem("version",version);
      return;
    }
  }

  updateSettings(username) {
    let v = Number(localStorage.getItem("version"));
    if(v !== version){
      for(let i=v+1;i<=version;i++){
        applyVersionChange(username,i);
      }
    }
    localStorage.setItem("version",version);
  }

  getWebsocket() {
    return this.websocket;
  }

  setupSettings(username) {
    let settings = JSON.parse(localStorage.getItem("settings"));
    if(settings === null){
      settings = {};
      settings[username] = {};
      localStorage.setItem("settings",JSON.stringify(settings));
      return true;
    }
    if(settings[username] === undefined){
      settings[username] = {};
      localStorage.setItem("settings",JSON.stringify(settings));
      return true;
    }
    return false;
  }

  handleMessage(evt) {
    let result = JSON.parse(evt.data)
    console.log(result);
    if (result.msgType === 250) {
      this.ccyFormat = this.ccyFormat.concat([result])
    }
    if (result.msgType === 261) {
      this.marketDefArray = this.marketDefArray.concat([result])
    }
    if (result.msgType === 268) {
      this.securityDefArray = this.securityDefArray.concat([result])
    }
    if (result.msgType === 300) {
      this.tradingAccountsArray = this.tradingAccountsArray.concat([result]);
    }
    if (result.msgType === 305) {
      let updatedArray = this.cpAccounts.concat([]);
      result.entries.forEach(el => {
        updatedArray = AccountService.updateCpAccounts(el,updatedArray);
      });
      this.cpAccounts = updatedArray;
    }
    if (result.msgType === 310) {
      let updatedArray = this.currencyRisk;
      result.entries.forEach(el => {
        updatedArray = AccountService.updateCurrencyRisk(el,updatedArray);
      });
      this.currencyRisk = updatedArray;
    }
    if (result.msgType === 325) {
      this.businessDayArray = this.businessDayArray.concat([result]);
    }
    if (result.msgType === 20 && result.success === true) {
      if(!this.setupSettings(this.state.username))
        this.updateSettings(this.state.username);
      this.props.SetCurrentUser(this.state.username);
      this.props.InitQuoteAggregation(this.state.username);
      this.props.InitConfirmation(this.state.username);
      this.props.InitAlert(this.state.username);
      this.props.InitTheme(this.state.username);
      this.props.InitLiveColumns(this.state.username);
      this.props.InitOrderHistoryColumns(this.state.username);
      this.props.InitOrderReviseColumns(this.state.username);
      this.props.InitCpColumns(this.state.username);
      this.props.InitCcyColumns(this.state.username);
      this.props.InitTradeColumns(this.state.username);
      this.redirect = <Redirect to = {{
        pathname : "/home",
        state : {
          fromLogin: true,
          tradingAccountsArray: this.tradingAccountsArray,
          securityDefArray: this.securityDefArray,
          marketDefArray: this.marketDefArray,
          cpAccounts: this.cpAccounts,
          currencyRisk: this.currencyRisk,
          businessDayArray: this.businessDayArray,
          ccyFormat: this.ccyFormat,
          username: this.state.username,
        },
      }}
      />
      this.setState({
        auth: true
      });
    }
    if (result.msgType === 20 && result.success === false) {
      this.setState({message: result.text});
    }
    if (result.msgType === 20 || result.msgType === 19) {
      this.setState({loading: false});
    }
  }

  loginHandler(motherEnv) {
    this.setState({username: this.refs.username.value});
    this.setState({password: this.refs.password.value});
    this.setState({loading: true});
    this.props.reopenWebSocket(motherEnv);
  }

  sendCredentials() {
    let username = this.state.username;
    let password = this.state.password;
    this.getWebsocket().send(JSON.stringify({
      "msgType": 19,
      "tag": 12345,
      "senderCompID": 1,
      "senderSubID": 1,
      "username": username,
      "password": password
    }));
  }

  render() {
    if(this.state.loading) {
      return (
        <div style={{backgroundColor:'white',height:'100vh',width:'100%',position:'absolute',top:0}}>
          <Bootstrap/>
          <FontAwesome/>
          <div className="d-flex justify-content-center mt-5">
            <div className="loader"></div>
          </div>
        </div>
      );
    }
    else{
      return (
        <div style={{backgroundColor:'white',height:'100vh',width:'100%',position:'absolute',top:0}}>
          <Bootstrap/>
          <FontAwesome/>
          {this.state.auth ? this.redirect : null}
          <div className="container mt-2">
          	<div className="d-flex justify-content-center h-100">
          		<div className="card">
          			<div className="card-header text-secondary">
          				<h3>Sign In</h3>
          			</div>
          			<div className="card-body">
          				<form>
          					<div className="input-group form-group">
          						<div className="input-group-prepend">
          							<span className="input-group-text"><i className="fas fa-user"></i></span>
          						</div>
          						<input type="text" className="form-control inputStyle" ref="username" placeholder="username"/>
          					</div>
          					<div className="input-group form-group">
          						<div className="input-group-prepend">
          							<span className="input-group-text"><i className="fas fa-key"></i></span>
          						</div>
          						<input type="password" ref="password" className="form-control inputStyle" placeholder="password"/>
          					</div>
          					<div className="form-group d-flex">
          						<input value="Login" className="btn w-50 float-right login_btn mr-1" onClick={() => this.loginHandler(false)}/>
                      <input value="MotherEnv" className="btn w-50 float-right login_btn" onClick={() => this.loginHandler(true)}/>
          					</div>
          				</form>
                  <p className="text-center text-danger">{this.state.message}</p>
          			</div>
          		</div>
          	</div>
          </div>
        </div>
      );
    }
  }
}

export default connect(null,actions)(Login);
