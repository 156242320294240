const initial = {
  cpAccountID: {
    show: true,
    grow: 1,
  },
  cpAccountName: {
    show: true,
    grow: 1,
  },
  nop: {
    show: true,
    grow: 1,
  },
  gop: {
    show: true,
    grow: 1,
  },
  util: {
    show: true,
    grow: 1,
  }
}

export default (state = [], action) => {
  let settings = JSON.parse(localStorage.getItem("settings"));
  switch(action.type){
    case "UPDATE_CP_COLUMNS":
      settings[action.payload.username].cpColumns = action.payload.data;
      localStorage.setItem("settings",JSON.stringify(settings));
      return action.payload.data;
    case "INIT_CP_COLUMNS":
      if(settings[action.payload].cpColumns === undefined){
        settings[action.payload].cpColumns = initial;
        localStorage.setItem("settings",JSON.stringify(settings));
      }
      return settings[action.payload].cpColumns;
    default:
      return state;
  }
}
