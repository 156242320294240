import React from 'react';
import './CustomDate.css';
import { connect } from 'react-redux';
import { theme } from '../../utils/Theme';

class CustomDate extends React.Component {
  render () {
    return (
      <div className="d-flex flex-row align-items-center justify-content-center">
        <div className="dateContainer">
          <input
            className={"w-100 h-75 " + theme(this.props.theme,'input')}
            onClick={this.props.onClick}
            value={this.props.value}/>
        </div>
        <div className="fakeContainer">
          <i className="fas fa-angle-up" style={{color:theme(this.props.theme,'body')}}></i>
          <i className="fas fa-angle-down" style={{color:theme(this.props.theme,'body')}}></i>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return{
    theme: state.theme,
  }
}

export default connect(mapStateToProps,null)(CustomDate);
