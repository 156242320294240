import React from 'react';
import { theme } from '../../utils/Theme';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Modal from 'react-responsive-modal';
import './ConfirmationModal.css';

let noFunction;

let yesFunction;

let message;

class ConfirmationModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      open: this.props.confirmationModal,
    }
  }

  componentDidUpdate(){
    if(this.state.open !== this.props.confirmationModal)
      this.setState({open: this.props.confirmationModal});
  }

  static setNoFunction(func){
    noFunction = func;
  }

  static setYesFunction(func){
    yesFunction = func;
  }

  static setMsg(msg){
    message = msg;
  }

  closeModal = () => {
    this.props.ToggleConfirmationModal();
  }

  render(){
    return(
      <Modal
        open={this.state.open}
        onClose={this.closeModal}
        center
        closeOnEsc={false}
        showCloseIcon={false}
        animationDuration={200}
        styles={{
          modal:{
            maxWidth:300,
            width:300,
            backgroundColor:theme(this.props.theme,'bg'),
            color:theme(this.props.theme,'text'),
          }
        }}
      >
        <div className="d-flex flex-column">
          <div className="mb-2">{message}</div>
          <div className="d-flex flex-row justify-content-end w-100">
            <div onClick={noFunction} className="errorButton mr-1">NO</div>
            <div onClick={yesFunction} className="successButton">YES</div>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return{
    confirmationModal: state.confirmationModal,
    theme: state.theme,
  }
}

export default connect(mapStateToProps,actions)(ConfirmationModal);
