import React from 'react';

class AccountService extends React.Component {
  static updateCpAccounts(newData,originalArray) {
    let cpAccounts = originalArray;
    let cac = cpAccounts.find(el => el.accountID === newData.accountID);
    let cacIndex = cpAccounts.findIndex(el => el.accountID === newData.accountID);
    if(cac === undefined) {
      cpAccounts.push(newData);
    }
    else{
      newData.entries.forEach(ent => {
        let cp = cac.entries.find(el => el.cpAccountID === ent.cpAccountID);
        let cpIndex = cac.entries.findIndex(el => el.cpAccountID === ent.cpAccountID);
        if(cp === undefined){
          cac.entries.push(ent)
        }
        else{
          if(newData.type === 0) {
            cac.entries[cpIndex].GOP = ent.GOP;
            cac.entries[cpIndex].NOP = ent.NOP;
            cac.entries[cpIndex].cpAccountID = ent.cpAccountID;
            cac.entries[cpIndex].isEnabled = ent.isEnabled;
            cac.entries[cpIndex].isInfinity_GOP = ent.isInfinity_GOP;
            cac.entries[cpIndex].isInfinity_NOP = ent.isInfinity_NOP;
          }
          else{
            cac.entries[cpIndex].uGOP = ent.GOP
            cac.entries[cpIndex].uNOP = ent.NOP
          }
        }
      });
      cpAccounts[cacIndex] = cac;
    }
    return cpAccounts;
  }

  static updateCurrencyRisk(newData,originalArray) {
    let currencyRisk = originalArray;
    let cac = currencyRisk.find(el => el.accountID === newData.accountID);
    let cacIndex = currencyRisk.findIndex(el => el.accountID === newData.accountID);
    if(cac === undefined) {
      if(newData.type === 1){
        newData.entries.forEach((el,index) => {
          newData.entries[index].uNOP = "N/A";
          delete newData.entries[index].NOP;
        });
        currencyRisk.push(newData);
      }
      else
        currencyRisk.push(newData);
    }
    else{
      newData.entries.forEach(ent => {
        let cp = cac.entries.find(el => el.currency === ent.currency);
        let cpIndex = cac.entries.findIndex(el => el.currency === ent.currency);
        if(cp === undefined){
          cac.entries.push(ent)
        }
        else{
          if(newData.type === 0) {
            cac.entries[cpIndex].NOP = ent.NOP;
            cac.entries[cpIndex].currency = ent.currency;
            cac.entries[cpIndex].isEnabled = ent.isEnabled;
            cac.entries[cpIndex].isInfinity = ent.isInfinity;
          }
          else{
            if(newData.realizedPnL !== undefined)
              cac.realizedPnL = newData.realizedPnL;
            cac.entries[cpIndex].uNOP = ent.NOP
            cac.entries[cpIndex].realizedPnL = ent.realizedPnL
          }
        }
      });
      currencyRisk[cacIndex] = cac;
    }
    return currencyRisk;
  }

  static updateTradingAccountsArray(newData,originalArray) {
    let tradingAccountsArray = originalArray;
    let acc = tradingAccountsArray.find(el => el.accountID === newData.accountID);
    let accIndex = tradingAccountsArray.findIndex(el => el.accountID === newData.accountID);
    if(acc === undefined) {
      tradingAccountsArray.push(newData);
    }
    else{
      newData.entries.forEach(ent => {
        let cp = acc.entries.find(el => el.cpAccountID === ent.cpAccountID);
        let cpIndex = acc.entries.findIndex(el => el.cpAccountID === ent.cpAccountID);
        if(cp === undefined) {
          acc.entries.push(ent);
        }
        else{
          acc.entries[cpIndex] = ent;
        }
      });
      tradingAccountsArray[accIndex] = acc;
    }
    return tradingAccountsArray;
  }
}

export default AccountService;
